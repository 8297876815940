import React, { Component, Fragment} from 'react'
import Tooltip from 'react-simple-tooltip';

export default class HeaderForm extends Component {
    render() {
        return (
            <Fragment>
                <div className="header-group d-flex justify-content-between align-items-center">
                    <h5 className="mb-2">{this.props.title}</h5>
                    <Tooltip background="#666" border="#666" content={this.props.help} placement={this.props.position} fontSize='13px'>
                        <i className="far fa-question-circle mr-2"></i>
                    </Tooltip>
                </div>
            </Fragment>
        )
    }
}
