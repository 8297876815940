import React, { Component } from 'react';

class AlertForm extends Component {
    render() {

        var code = this.props.code;
        var divClass = "alert alert-success text-left mt-4";

        switch (code) {
            case 404:
                divClass = 'alert alert-warning text-left mt-4';
                break;
            default:
                break;
        }
        if (this.props.show === false) {
            return false;
        } else {
            return (
                <div className={divClass} role="alert">
                    <h6 className="alert-heading"><i className="fas fa-exclamation-triangle mr-2 fa-lg"></i> {this.props.type}</h6>
                    <p>{this.props.body}</p>
                    <hr />
                    <p className="mb-0">Código de error: {this.props.code}</p>
                </div>
            );
        }
    }
}

export default AlertForm;