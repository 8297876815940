import React, { Component, Fragment } from 'react';
// import AddCriteriosNodo from './AddCriteriosNodo';


class AddCriterios extends Component {

    constructor(props) {
        super(props)
        this.state = {
            count: 0
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleValue = this.handleValue.bind(this);
    }

    handleChange(e) {
        this.props.change(e);
        // const { value, name } = e.target;
        const { value } = e.target;
        this.setState({
            tipo: value
        })
    }

    handleValue() {
        if (this.state.tipo === '') {
            return (
                <div className="form-group ml-2">
                    <label htmlFor="valor">Valor</label>
                    <input type="number" className="form-control" name="valor" placeholder="Valor" onChange={this.props.change} required></input>
                    <div className="invalid-feedback">Ingresa un valor</div>
                    <div className="valid-feedback">Excelente!</div>
                </div>
            )
        } else if (this.state.tipo === 'NOTA') {
            return (
                <div className="form-group ml-2">
                    <label htmlFor="valor">Valor</label>
                    <input type="number" className="form-control" name="valor" placeholder="Valor" onChange={this.props.change} required></input>
                    <div className="invalid-feedback">Ingresa un valor</div>
                    <div className="valid-feedback">Excelente!</div>
                </div>
            )
        } else if (this.state.tipo === 'FECHA_FIN') {
            return (
                <div className="form-group ml-2">
                    <label htmlFor="valor">Valor</label>
                    <input type="date" className="form-control" name="valor" placeholder="Valor" onChange={this.props.change} required></input>
                    <div className="invalid-feedback">Ingresa un valor</div>
                    <div className="valid-feedback">Excelente!</div>
                </div>
            )
        }
        else if (this.state.tipo === 'ASISTENCIA') {
            return (
                <div className="form-group ml-2">
                    <label htmlFor="valor">Valor</label>
                    <input type="number" className="form-control" name="valor" placeholder="Valor" onChange={this.props.change} required></input>
                    <div className="invalid-feedback">Ingresa un valor</div>
                    <div className="valid-feedback">Excelente!</div>
                </div>
            )
        }
    }

    render() {
        if (this.props.tipo === "aprobacion_verificado" || this.props.tipo === '') {
            return false;
        } else {
            return (
                <Fragment>
                    <div className="divider mt-5 mb-5"></div>
                    {this.props.header}

                    <div id="criterios">
                        <div className="d-flex justify-content-between align-items-center mb-2 criterios-group" key={this.props.count}>
                            <div className="form-group">
                                <span className="criterio-count">1</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="tipo">Tipo</label>
                                <select type="text" value="NOTA" className="form-control" name="tipo" onChange={this.handleChange} disabled={true}>
                                    <option value="">Tipo</option>
                                    <option value="NOTA">Nota</option>
                                    <option value="FECHA_FIN">Fecha de fin</option>
                                    <option value="ASISTENCIA">Asistencia</option>
                                </select>
                                <div className="invalid-feedback">Selecciona un tipo de criterio</div>
                                <div className="valid-feedback">Excelente!</div>
                            </div>
                            <div className="form-group ml-2">
                                <label htmlFor="condicional">Operador</label>
                                <select type="text" className="form-control" name="condicional" onChange={this.props.change}>
                                    <option value="">Operador</option>
                                    <option value=">">Mayor que</option>
                                    <option value="<">Menor que</option>
                                    <option value="=">Igual que</option>
                                </select>
                                <div className="invalid-feedback">Selecciona un tipo de operador</div>
                                <div className="valid-feedback">Excelente!</div>
                            </div>
                            <div className="form-group ml-2">
                                <label htmlFor="valor">Valor</label>
                                <input type="number" className="form-control" name="valor" placeholder="Valor" onChange={this.props.change}></input>
                                <div className="invalid-feedback">Ingresa un valor</div>
                                <div className="valid-feedback">Excelente!</div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mb-2 criterios-group" key={this.props.count}>
                            <div className="form-group">
                                <span className="criterio-count">2</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="tipo">Tipo</label>
                                <select type="text" value="ASISTENCIA" className="form-control" name="tipo" onChange={this.handleChange} disabled={true}>
                                    <option value="">Tipo</option>
                                    <option value="NOTA">Nota</option>
                                    <option value="FECHA_FIN">Fecha de fin</option>
                                    <option value="ASISTENCIA">Asistencia</option>
                                </select>
                                <div className="invalid-feedback">Selecciona un tipo de criterio</div>
                                <div className="valid-feedback">Excelente!</div>
                            </div>
                            <div className="form-group ml-2">
                                <label htmlFor="condicional">Operador</label>
                                <select type="text" className="form-control" name="condicional" onChange={this.props.change}>
                                    <option value="">Operador</option>
                                    <option value=">">Mayor que</option>
                                    <option value="<">Menor que</option>
                                    <option value="=">Igual que</option>
                                </select>
                                <div className="invalid-feedback">Selecciona un tipo de operador</div>
                                <div className="valid-feedback">Excelente!</div>
                            </div>
                            <div className="form-group ml-2">
                                <label htmlFor="valor">Valor</label>
                                <input type="number" className="form-control" name="valor" placeholder="Valor" onChange={this.props.change}></input>
                                <div className="invalid-feedback">Ingresa un valor</div>
                                <div className="valid-feedback">Excelente!</div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mb-2 criterios-group" key={this.props.count}>
                            <div className="form-group">
                                <span className="criterio-count">3</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="tipo">Tipo</label>
                                <select type="text" value="FECHA_FIN" className="form-control" name="tipo" onChange={this.handleChange} disabled={true}>
                                    <option value="">Tipo</option>
                                    <option value="NOTA">Nota</option>
                                    <option value="FECHA_FIN">Fecha de fin</option>
                                    <option value="ASISTENCIA">Asistencia</option>
                                </select>
                                <div className="invalid-feedback">Selecciona un tipo de criterio</div>
                                <div className="valid-feedback">Excelente!</div>
                            </div>
                            <div className="form-group ml-2">
                                <label htmlFor="condicional">Operador</label>
                                <select type="text" className="form-control" name="condicional" onChange={this.props.change}>
                                    <option value="">Operador</option>
                                    <option value=">">Mayor que</option>
                                    <option value="<">Menor que</option>
                                    <option value="=">Igual que</option>
                                </select>
                                <div className="invalid-feedback">Selecciona un tipo de operador</div>
                                <div className="valid-feedback">Excelente!</div>
                            </div>
                            <div className="form-group ml-2">
                                <label htmlFor="valor">Valor</label>
                                <input type="date" className="form-control" name="valor" placeholder="Valor" onChange={this.props.change}></input>
                                <div className="invalid-feedback">Ingresa un valor</div>
                                <div className="valid-feedback">Excelente!</div>
                            </div>
                        </div>

                        
                    </div>
                </Fragment>
            )
        }

    }
}

export default AddCriterios;
