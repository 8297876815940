import React, { Component } from 'react';
import Navigation from '../components/Navigation/Navigation';
import ListTemplates from '../components/templates/ListTemplates';
import SecondaryNavigationEmptyTemplate from '../components/SecondaryNavigation/SecondaryNavigationEmptyTemplate';

class certTemplates extends Component{
  
  render(){
    return(
      <React.Fragment>
        <Navigation/>
        <SecondaryNavigationEmptyTemplate
          title="Todos los templates"
        />
        <ListTemplates/>
      </React.Fragment>
    )
  }
  
}

export default certTemplates;
