import React, { Component } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar'
import Navigation from '../components/Navigation/Navigation';
import SecondaryNavigationAsistencias from '../components/SecondaryNavigation/SecondaryNavigationAsistencias';
import { ExcelRenderer, OutTable } from 'react-excel-renderer';
import { Animated } from "react-animated-css";

// const mySweetTheme = {
//   rows: {
//     height: '60px'
//   },
// }

class routeAsistencias extends Component {

  constructor(props) {
    super(props)
    this.state = {
      rows: null,
      procesado: null
    }
    this.handleSubirArchivo = this.handleSubirArchivo.bind(this);
    this.procesarAsistencias = this.procesarAsistencias.bind(this);
    this.loadingProcesando = this.loadingProcesando.bind(this);
    this.handleClickCerrar = this.handleClickCerrar.bind(this);
  }

  handleSubirArchivo(archivo, certificacion) {
    ExcelRenderer(archivo, (err, resp) => {
      if (err) {
        console.log(err);
      }
      else {
        // console.log(resp.rows)
        this.setState({
          cols: resp.cols,
          rows: resp.rows,
          certificacionId: certificacion
        });
        // console.log(this.state.rows)
      }
    });
  }

  obtenerCertificacion() {

    this.setState({
      procesado: false
    })

    var myHeaders = new Headers();
    myHeaders.append("Auth-Token", localStorage.getItem('token'));
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    fetch(window.$UrlApiDev + "/certificacion/" + this.state.certificacionId, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        this.setState({
          id_cliente: result.id_cliente,
          id_producto: result.id_producto,
          id_certificacion: result.id
        })
        if (result.id_cliente) {
          this.procesarAsistenciasFinal();
        }
      })
      .catch(error => console.log('error', error));
  }

  procesarAsistencias() {
    this.obtenerCertificacion();
  }

  procesarAsistenciasFinal() {
    var count = 0;
    var totalRows = this.state.rows.length;

    for (let i = 0; i < this.state.rows.length; i++) {
      var myHeaders = new Headers();
      myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");
      myHeaders.append("cliente", this.state.id_cliente);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch("https://octaviodev.capabiliaserver.com/api/v1/usuarios?filter={\"email\":\"" + this.state.rows[i][0] + "\"}", requestOptions)
        .then(response => response.json())
        .then(result => {

          console.log(result)

          if (this.state.rows[i] !== this.state.rows[0]) {
            var myHeaders = new Headers();
            myHeaders.append("Auth-Token", localStorage.getItem('token'));
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
              "id_usuario": parseInt(result[0].id),
              "id_certificacion": parseInt(this.state.id_certificacion),
              "id_producto": parseInt(this.state.id_producto),
              "asistencia": parseInt(this.state.rows[i][1])
            });

            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };

            fetch(window.$UrlApiDev + "/asistencia", requestOptions)
              .then(response => response.json())
              .then(result => {
                count = count + 1
                this.setState({
                  count: count * 100 / totalRows
                })

                console.log(result)

                if (count === this.state.rows.length - 1) {
                  this.setState({
                    procesado: true
                  })
                }

              })
              .catch(error => console.log('error', error));

          }
        })
        .catch(error => {
          count = count + 1
          this.setState({
            count: count * 100 / totalRows
          })

          this.setState({
            usuarioData: null,
            comprasLoading: null
          })

          if (count === this.state.rows.length - 1) {
            this.setState({
              procesado: true
            })
          }

          console.log('error', error)
        });
    }
  }


  loadingProcesando() {
    if (this.state.procesado === false) {
      return (
        <div className="loading-status">
          <div className="loading-status-container">
            <h4 className="global-title-1">Procesando asistencias</h4>
            <p>No cierres esta página</p>
            <ProgressBar animated variant="warning" now={this.state.count} />

          </div>
        </div>
      )
    } else if (this.state.procesado === true) {
      return (
        <div className="loading-status">
          <div className="loading-status-container">
            <h4 className="global-title-1">Procesado con éxito!</h4>
            <p>Ya puedes continuar</p>
            <ProgressBar animated variant="success" now={100} />
            <button onClick={this.handleClickCerrar} className="btn btn-primary mt-4">Cerrar</button>
          </div>
        </div>
      )
    } else {
      return false
    }
  }

  handleClickCerrar() {
    this.setState({
      procesado: null,
      rows: null,
      count: 0
    })
  }

  render() {
    if (this.state.rows === null) {
      return (
        <div>
          {this.loadingProcesando()}
          <Navigation />
          <SecondaryNavigationAsistencias
            title={`Carga de asistencias`}
            textButton="Cargar archivo"
            descarga={true}
            handleSubirArchivo={this.handleSubirArchivo}
          />
        </div>
      )
    } else {
      return (
        <div>
          <Navigation />
          <SecondaryNavigationAsistencias
            title={`Carga de asistencias`}
            textButton="Cargar archivo"
            descarga={true}
            handleSubirArchivo={this.handleSubirArchivo}
          />
          {this.loadingProcesando()}
          <Animated animationIn="slideInUp" animationOut="fadeOut" animationInDuration={380} isVisible={true}>
            <div className="container">
              <div className="row">
                <div className="col-12 d-flex justify-content-end">
                  <button className="btn btn-primary mt-5" onClick={this.procesarAsistencias}>Procesar asistencias</button>
                </div>
                <div className="col-lg-12">
                  <OutTable data={this.state.rows} columns={this.state.cols} tableClassName="table table-striped table-bordered" tableHeaderRowClass="heading" />
                </div>
              </div>
            </div>
          </Animated>
        </div>
      )

    }

  }

}

export default routeAsistencias;