import React, { Component, Fragment } from 'react';
import './Tinymce.css';
import store from '../../store';
import { connect } from 'react-redux';

class TextEditor extends Component {


    constructor(props) {
        super(props);
        this.state = {
            selectLanguage: '',
            selectWidth: '',
            selectHeight: '',
            html: '',
            validation: "needs-validation",
            open: store.getState().openTextEditor,
            templateSeleccionada: null,
            name: '',
            buttonAction: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.sendForm = this.sendForm.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleEditorChange = (e) => {
        this.setState({
            html: e.target.value
        })

    }

    handleClose() {
        store.dispatch({
            type: "OPEN_TEMPLATE",
            openTextEditor: false
        })
    }

    handleValidation() {
        if (!this.state.selectLanguage || !this.state.selectWidth || !this.state.selectHeight || !this.state.html || !this.state.orientacion) {
            return false;
        } else {
            return true;
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            validation: "needs-validation was-validated"
        });
        if (this.handleValidation() === true) {
            this.sendForm();
        };
    }

    sendForm() {
        this.setState({
            buttonAction: true
        })

        var myHeaders = new Headers();
        myHeaders.append("Auth-Token", localStorage.getItem('token'));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "nombre": this.state.name,
            "idioma": this.state.selectLanguage,
            "html": this.state.html,
            "width": this.state.selectWidth,
            "height": this.state.selectHeight,
            "orientacion": parseInt(this.state.orientacion)
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(window.$UrlApiDev + "/templates/", requestOptions)
            .then(response => response.json())
            .then(result => {
                store.dispatch({
                    type: "OPEN_TEMPLATE",
                    openTextEditor: false
                })
                store.dispatch({
                    type: "TEMPLATE_SELECTED",
                    template: result[0].id
                })
                this.setState({
                    selectLanguage: '',
                    selectWidth: '',
                    selectHeight: '',
                    html: '',
                    validation: "needs-validation",
                    buttonAction: false
                })
            })
            .catch(error => console.log('error', error));
    }

    butonsAction() {
        if (this.state.buttonAction) {
            return (
                <button disabled={true} className="btn btn-primary mr-2" onClick={() => this.handleButtonShow()}>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    <span className="sr-only">Loading...</span>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    <span className="sr-only">Loading...</span>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    <span className="sr-only">Loading...</span>
                         Creando...
                </button>
            )
        }
        return (
            <Fragment>
                <button className="btn btn-secondary mr-3" onClick={this.handleClose}>Cancelar</button>
                <button onClick={this.handleSubmit} className="btn btn-primary">Crear Template </button>
            </Fragment>
        )
    }

    render() {

        let select = {
            certTypes: ['Aprobacion', 'Participacion'],
            languages: ['Ingles', 'Español', 'Portugues', 'Catalan'],
            templates: ['Oversea', 'Barca']
        };

        if (this.props.open[this.props.open.length - 1] === false) {
            return false
        } else {
            return (
                <Fragment>
                    <form className={this.state.validation} noValidate>
                        <div className="container-fluid template-create d-flex flex-column align-items-center justify-content-between">
                            <div className="editor-header right-header row d-flex justify-content-between" >
                                <p>Crear template de Certificado</p>
                                <button onClick={this.handleClose} className="btn btn-close">
                                    <i className="fa fa-times"></i>
                                </button>
                            </div>

                            <div className="editor-body">

                                <div className="form-group form-editor d-flex flex-xl-row flex-lg-row flex-md-column flex-sm-columnflex-sm-column flex-xs-column bd-highlight">
                                    <div className="mr-3">
                                        <label>Nombre del template:</label>
                                        <input className="form-control" name="name" value={this.state.name} onChange={this.handleChange} required />
                                    </div>
                                    <div className="mr-3">
                                        <label>Idioma:</label>
                                        <select className="form-control" name="selectLanguage" value={this.state.selectLanguage} onChange={this.handleChange} required>
                                            <option value="">Selecciona el idioma</option>
                                            <option value="es">Español</option>
                                            <option value="en">Inglés</option>
                                            <option value="pt">Portugues</option>
                                            <option value="ca">Catalán</option>
                                            <option value="ar">Árabe</option>
                                            <option value="ru">Ruso</option>
                                        </select>
                                    </div>
                                    <div className="mr-3">
                                        <label>Ancho:</label>
                                        <select className="form-control" name="selectWidth" value={this.state.selectWidth} onChange={this.handleChange} required>
                                            <option value="">---</option>
                                            <option value="842">842px</option>
                                        </select>
                                    </div>
                                    <div className="mr-3">
                                        <label>Alto:</label>
                                        <select className="form-control" name="selectHeight" value={this.state.selectHeight} onChange={this.handleChange} required>
                                            <option value="">---</option>
                                            <option value="605">605px</option>
                                        </select>
                                    </div>
                                    <div className="mr-3">
                                            <label>Orientación:</label>
                                            <select className="form-control" name="orientacion" value={this.state.orientacion} onChange={this.handleChange} required>
                                                <option value="">Seleccione orientación</option>
                                                <option value="0">Horizontal</option>
                                                <option value="1">Vertical</option>
                                            </select>
                                        </div>
                                </div>

                                <div className="variables">
                                    <label className="mr-2"><b>Variables: </b></label>
                                    <span className="badge badge-pill badge-secondary mr-2">##USER_FULL_NAME##</span>
                                    <span className="badge badge-pill badge-secondary mr-2">##QR_IMAGE##</span>
                                    <span className="badge badge-pill badge-secondary mr-2">##DATE_MONTH_NAME##</span>
                                    <span className="badge badge-pill badge-secondary mr-2">##DATE_YEAR##</span>
                                    <span className="badge badge-pill badge-secondary mr-2">##COURSE_NAME##</span>
                                    <span className="badge badge-pill badge-secondary mr-2">##COURSE_LENGHT##</span>
                                </div>
                                <textarea 
                                    className="form-control"
                                    cols="10"
                                    rows="20"
                                    required={true}
                                    onChange={this.handleEditorChange}
                                />

                            </div>
                            <footer className="editor-footer row d-flex flex-row bd-highlight justify-content-end pt-3 pb-3">
                                {this.butonsAction()}
                            </footer>
                        </div>
                    </form>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    open: state.openTextEditor
})


export default connect(mapStateToProps)(TextEditor);