import React, { Component } from 'react';
import Navigation from '../components/Navigation/Navigation';
import UsuariosForm from '../components/Usuarios/UsuariosForm';
import SecondaryNavigationEmpty from '../components/SecondaryNavigation/SecondaryNavigationEmpty';
import ListUsuarios from '../components/Usuarios/ListUsuarios';

class listUsuarios extends Component{
  
  render(){
    return(
      <div>
        <Navigation/>
        <SecondaryNavigationEmpty
          title={`Usuarios`}
          textButton="Crear Usuario"
          overlayContent={UsuariosForm}
        />
        <ListUsuarios/>
      </div>
    )
  }
  
}

export default listUsuarios;