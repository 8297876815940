import React, {Component} from 'react';

class LoaderSecondary extends Component{

    render(){
        return(
            <React.Fragment>
                <div className="spinner-grow spinner-grow-sm text-secondary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow spinner-grow-sm text-secondary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow spinner-grow-sm text-secondary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            <span className="ml-3">{this.props.texto}</span>
            <div className="button" onClick={this.props.accion}></div>
            </React.Fragment>       
        )
    }
    
}

export default LoaderSecondary;