import React, { Component, Fragment } from 'react';
import LoaderSecondary from '../components/Global/LoaderSecondary';
import Loader from '../components/Global/Loader';
import Navigation from '../components/Navigation/Navigation';
import HeaderForm from '../components/Global/HeaderForm';
import ListSecciones from '../components/Enrolment/ListSecciones';
import { Animated } from "react-animated-css";
import { Link } from 'react-router-dom';
import IconLarge from '../components/Global/IconLarge';
import $ from 'jquery';


class routeProductoId extends Component {

    constructor(props) {
        super(props)
        this.state = {
            productoLoading: true,
            producto: false,
            handleObtenerSecciones: false,
            ShowHideModalEditarCurso: false,
            ShowHideModalEditarSeccion: false,
            seccionEditableNombre: '',
            seccionEditableInicio: '',
            seccionEditableFin: '',
            overlayShowCurso: false,
            overlayShowSeccion: false,
            overlayShowSeccionMasiva: false,
            cursoId: false,
            overlayShow: false,
            dataCurso: {
                curso_nombre: false,
                curso_idioma: false
            }
        }
        this.handleObtenerProductos = this.handleObtenerProductos.bind(this);
        this.handleObtenerSecciones = this.handleObtenerSecciones.bind(this);
        this.handleEditarCurso = this.handleEditarCurso.bind(this);
        this.modalEditarCurso = this.modalEditarCurso.bind(this);
        this.overlayShowHideCurso = this.overlayShowHideCurso.bind(this);
        this.overlayNuevoCurso = this.overlayNuevoCurso.bind(this);
        this.productoExito = this.productoExito.bind(this);
        this.handleSubmitCurso = this.handleSubmitCurso.bind(this);
        this.handleInputCurso = this.handleInputCurso.bind(this);
        this.handleValidationCurso = this.handleValidationCurso.bind(this);
        this.handlePostCurso = this.handlePostCurso.bind(this);
        this.updateProduct = this.updateProduct.bind(this);
    }

    componentDidMount() {
        var overlay = new URLSearchParams(this.props.location.search).get("overlay")

        this.handleObtenerProductos();
        if (overlay) {
            this.setState({
                overlayShow: true
            })
        }
    }

    handleObtenerSecciones(cursoId) {
        this.setState(prevState => ({
            cursoId: cursoId,
            handleObtenerSecciones: !prevState.handleObtenerSecciones
        }));
    }

    handleObtenerProductos(value) {
        this.setState({
            productoLoading: true
        })

        var myHeaders = new Headers();
        myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");
        myHeaders.append("cliente", value);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(window.$UrlOctavio + "/productos/" + this.props.match.params.id, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                this.setState({
                    productoLoading: false,
                    producto: result
                })

            })
            .catch(error => {
                console.log('error', error)
            })
    }



    handleEditarCurso(curso) {
        this.setState(prevState => ({
            ShowHideModalEditarCurso: !prevState.ShowHideModalEditarCurso,
            cursoEditable: curso
        }));
    }

    modalEditarCurso() {
        if (this.state.ShowHideModalEditarCurso === true) {
            return (
                <div className="my-modal">
                    <div className="my-modal-content">
                        <div className="my-modal-header">
                            <div className="row d-flex justify-content-between align-items-center">
                                <h6>Editar curso</h6>
                                <button onClick={this.handleEditarCurso} className="btn btn-close"><i className="fa fa-times"></i></button>
                            </div>
                        </div>
                        <div className="my-modal-body text-left">
                            <div className="form-group">
                                <label htmlFor="curso_nombre">Nombre</label>
                                <input id="curso_nombre" type="text" className="form-control" name="curso_nombre" placeholder={this.state.cursoEditable.nombre} required></input>
                                <div className="invalid-feedback">Ingresa el nombre del curso</div>
                                <div className="valid-feedback">Excelente!</div>
                            </div>
                        </div>
                        <div className="my-modal-footer d-flex justify-content-center align-items-stretch">
                            <button className="btn btn-secondary mr-2" onClick={this.handleEditarCurso}>Cancelar</button>
                            <button className="btn btn-primary">Guardar</button>
                        </div>
                    </div>
                </div>
            )
        } else {
            return false;
        }
    }






    // componenteSecciones() {
    //     if (this.state.seccionesLoading === true) {
    //         return (
    //             <div className="card-body mt-2 d-flex align-items-center justify-content-center">
    //                 <Loader></Loader>
    //             </div>
    //         )
    //     }
    //     else {
    //         return (
    //             <Animated animationIn="slideInUp" animationOut="fadeOut" animationInDuration={380} isVisible={true}>
    //                 <hr />
    //                 <div className="card-body">
    //                     <div className="d-flex align-items-center justify-content-between mt-2">
    //                         <h4>Secciones</h4>
    //                         <div>
    //                             <button className="btn btn-secondary mr-2" onClick={() => this.overlayShowHideSeccionMasiva()}>Añadir secciónes por lotes</button>
    //                             <button className="btn btn-primary" onClick={() => this.overlayShowHideSeccion()}>Añadir sección manualmente</button>
    //                         </div>
    //                     </div>
    //                     <hr />
    //                     <ul className="list-group mt-2">
    //                         {
    //                             this.state.secciones === false ? "nada" :
    //                                 this.state.secciones.map(seccion => {
    //                                     return <li key={seccion.idCanvas} className="list-group-item">
    //                                         <div className="d-flex align-items-center justify-content-between">
    //                                             <div>
    //                                                 <span className="badge badge-primary mr-2">{seccion.idCanvas}</span>
    //                                                 <span>{seccion.nombre}</span>
    //                                             </div>
    //                                             <div>
    //                                                 <button className="btn btn-secondary mr-2" onClick={() => this.handleEditarSeccion(seccion.nombre, seccion.fechaInicio, seccion.fechaFin)}>Editar</button>
    //                                             </div>
    //                                         </div>
    //                                     </li>
    //                                 })
    //                         }
    //                     </ul>
    //                 </div>
    //             </Animated>
    //         )
    //     }
    // }

    overlayShowHideCurso() {
        this.setState(prevState => ({
            overlayShowCurso: !prevState.overlayShowCurso
        }));
    }

    overlayShowHide() {
        this.setState(prevState => ({
            overlayShow: !prevState.overlayShow
        }));
    }

    handleInputCurso(e) {
        const { value, name } = e.target;
        this.setState({
            dataCurso: {
                ...this.state.dataCurso,
                [name]: value
            },
        })
        console.log(this.state.dataCurso)
    }

    handleSubmitCurso(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            validation: "needs-validation was-validated",
        });
        if (this.handleValidationCurso() === true) {
            // console.log("No pasó")
            // console.log(this.state.dataCurso)
            return false;
        } else {
            // console.log("Pasooo")
            // console.log(this.state.dataCurso)
            this.handlePostCurso();
        };

    }

    handlePostCurso() {
        this.setState({
            buttonDisable: true
        })

        var myHeaders = new Headers();
        myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "cliente": parseInt(this.state.producto.cliente),
            "nombre": this.state.dataCurso.curso_nombre,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        console.log(JSON.parse(raw))
        console.log(this.state)
        // return false;

        fetch(window.$UrlOctavio + "/cursos", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                if (result.idCanvas !== undefined) {
                    this.updateProduct(result.idCanvas);
                }
            })
            .catch(error => {
                console.log('error', error)
                this.setState({
                    buttonDisable: false
                })
            });
    }

    updateProduct(id) {
        var myHeaders = new Headers();
        myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "nombre": this.state.producto.nombre,
            "sku": this.state.producto.sku,
            "categorias": [22, 23, 24, 25],
            "estado": 1,
            "idiomas": [
                "en",
                "es",
                "ca",
                "pt"
            ],
            "noCarrousel": false,
            "tipo": "producto",
            "idLms": 0,
            "cursos": {
                "en": [
                    parseInt(id),
                    parseInt(id)
                ],
                "es": [
                    parseInt(id),
                    parseInt(id)
                ],
                "ca": [
                    parseInt(id),
                    parseInt(id)
                ],
                "pt": [
                    parseInt(id),
                    parseInt(id)
                ]
            }
        });

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        console.log(JSON.parse(raw))
        console.log(parseInt(this.state.producto.id))

        fetch(window.$UrlOctavio + "/productos/" + parseInt(this.state.producto.id), requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                this.setState({
                    buttonDisable: false,
                    overlayShowCurso: false
                })
            })
            .catch(error => {
                console.log('error', error)
                this.setState({
                    buttonDisable: false,
                    overlayShowCurso: false
                })
            });
    }

    handleValidationCurso() {
        var validation = [];
        this.state.dataCurso.curso_nombre !== false ? validation.push(true) : validation.push(false)
        this.state.dataCurso.curso_precio !== false ? validation.push(true) : validation.push(false)
        return validation.includes(false);
    }



    overlayNuevoCurso() {
        if (this.state.overlayShowCurso) {
            return <div className="right-overlay">
                <div className="right-content bg-white shadow-lg">
                    <div className="right-header">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div>
                                <h6>Añadir nuevo Curso</h6>
                            </div>
                            <div>
                                <button className="btn btn-close" onClick={() => this.overlayShowHideCurso()}>
                                    <i className="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="right-body bg-white">
                        <div className="row">
                            <div className="col-lg-12">

                                <form onSubmit={this.handleSubmit} className={this.state.validation} noValidate>

                                    <HeaderForm title="Información del curso" position="bottom"></HeaderForm>

                                    <div className="form-group">
                                        <label htmlFor="curso_nombre">Nombre del curso</label>
                                        <input id="curso_nombre" type="text" className="form-control" name="curso_nombre" placeholder="Nombre del curso" onChange={this.handleInputCurso} required></input>
                                        <div className="invalid-feedback">Ingresa el nombre</div>
                                        <div className="valid-feedback">Excelente!</div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="curso_idioma">Idioma</label>
                                        <select id="curso_idioma" type="text" className="form-control" name="curso_idioma" onChange={this.handleInputCurso} required>
                                            <option value="">Selecciona un idioma</option>
                                            <option value="en">Inglés</option>
                                            <option value="es">Español</option>
                                            <option value="pt">Portugués</option>
                                            <option value="ca">Catalán</option>
                                            <option value="ar">Árabe</option>
                                            <option value="ru">Ruso</option>
                                        </select>
                                        <div className="invalid-feedback">Selecciona un idioma</div>
                                        <div className="valid-feedback">Excelente!</div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                    <div className="right-footer d-flex justify-content-center align-items-center">
                        {this.state.buttonDisable ? <button className="btn btn-primary mr-2" onClick={(e) => this.handleSubmitCurso(e)}>
                            <LoaderSecondary />
                        </button> : <button className="btn btn-primary mr-2" onClick={(e) => this.handleSubmitCurso(e)}>
                            Crear y añadir otro
                        </button>}
                        {this.state.buttonDisable ? false : <button disabled={this.state.buttonDisable} className="btn btn-secondary" onClick={() => this.overlayShowHideCurso()}>
                            Cancelar
                        </button>}
                    </div>
                </div>
            </div>
        }
    }

    renderProducto() {
        if (this.state.productoLoading === true) {
            return (
                <Loader></Loader>
            )
        } else {
            return (
                <Animated animationIn="slideInUp" animationOut="fadeOut" animationInDuration={380} isVisible={true}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                <div className="box-white sticky-top">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h6>Información del producto</h6>
                                        <button className="btn btn-primary">Editar</button>
                                    </div>
                                    <hr />
                                    <ul className="list-group">
                                        <li className="list-group-item">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>Id Octavio</span>
                                                <span className="badge badge-info">{this.state.producto.id}</span>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>SKU</span>
                                                <span className="badge badge-secondary">{this.state.producto.sku}</span>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>Tipo</span>
                                                <span className="badge badge-secondary">{this.state.producto.tipo}</span>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>No Carrousel</span>
                                                {this.state.producto.noCarrousel === false ? <span className="badge badge-danger">No</span> : <span className="badge badge-success">Si</span>}
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>Id Hubspot</span>
                                                <span className="badge badge-secondary">{this.state.producto.idHubspot}</span>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>Id Magento</span>
                                                <span className="badge badge-secondary">{this.state.producto.idMagento}</span>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>LMS</span>
                                                <span className="badge badge-secondary">Canvas</span>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>Categorías</span>
                                                <span className="badge badge-secondary">{this.state.producto.categorias}</span>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>Precio</span>
                                                <span className="badge badge-secondary">{this.state.producto.precio}</span>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>Cantidad de cuotas</span>
                                                <span className="badge badge-secondary">{this.state.producto.cantidadDeCuotas}</span>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>Estado</span>
                                                {this.state.producto.estado === 0 ? <span className="badge badge-danger">Inactivo</span> : <span className="badge badge-success">Activo</span>}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                                <div className="box-white">
                                    <div className="col-12 d-flex align-items-center justify-content-between">
                                        <h6 className="m-0">Información de cursos</h6>
                                        <button className="btn btn-primary" onClick={() => this.overlayShowHideCurso()}>Añadir curso</button>
                                    </div>
                                    <hr />
                                    <nav>
                                        <div className="nav nav-tabs" id="tabIdiomas" role="tablist">
                                            {Object.keys(this.state.producto.cursos).map((idioma, i) => (
                                                i === 0 ?
                                                    <a key={i} className="nav-link active" data-toggle="tab" href={`#id${i}`} role="tab" aria-selected="true">Idioma: {idioma}</a>
                                                    :
                                                    <a key={i} className="nav-link" data-toggle="tab" href={`#id${i}`} role="tab" aria-selected="true">Idioma: {idioma}</a>

                                            ))}
                                        </div>
                                    </nav>
                                    <div className="tab-content mt-3">
                                        {Object.keys(this.state.producto.cursos).map((idioma, i) => (
                                            i === 0 ?
                                                <div key={i} className="tab-pane fade active show" id={`id${i}`} role="tabpanel">
                                                    <div className="list-group">
                                                        {this.state.producto.cursos[idioma].map(item => {
                                                            return <li key={item.id} className="list-group-item">
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <div>
                                                                        <span className="badge badge-primary mr-2">{item.id}</span>
                                                                        <span>{item.nombre}</span>
                                                                    </div>
                                                                    <div>
                                                                        <button className="btn btn-secondary mr-2" onClick={() => this.handleEditarCurso(item)}>Editar</button>
                                                                        <button id={`botonSecciones${item.id}`} type="button" className="btn btn-secondary botonSecciones" onClick={() => this.handleObtenerSecciones(item)}>Ver secciones</button>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        })}
                                                    </div>
                                                </div>
                                                :
                                                <div key={i} className="tab-pane fade" id={`id${i}`} role="tabpanel">
                                                    <div className="list-group">
                                                        {this.state.producto.cursos[idioma].map(item => {
                                                            return <li key={item.id} className="list-group-item">
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <div>
                                                                        <span className="badge badge-primary mr-2">{item.id}</span>
                                                                        <span>{item.nombre}</span>

                                                                    </div>
                                                                    <div>
                                                                        <button className="btn btn-secondary mr-2" onClick={() => this.handleEditarCurso(item)}>Editar</button>
                                                                        <button id={`botonSecciones${item.id}`} type="button" className="btn btn-secondary" onClick={() => this.handleObtenerSecciones(item.id)}>Ver secciones</button>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        })}
                                                    </div>
                                                </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Animated>
            )
        }

    }

    productoExito() {
        if (this.state.overlayShow) {
            return (
                <div className="right-content bg-white shadow-lg">
                    <div className="right-header">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div>
                                <h6>Producto publicado</h6>
                            </div>
                            <div>
                                <button className="btn btn-close" onClick={() => this.props.overlayShowHide()}>
                                    <i className="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="right-body bg-white">
                        <div className="row">
                            <div className="col-lg-12 d-flex flex-column justify-content-center align-items-center">
                                <IconLarge icon="fa-check" size="4" color="text-success" animation="shake" />
                                <p><b>¡Producto publicado con éxito!</b></p>
                                <p>Tu producto ha sido publicado con éxito, ahora podrás cargar cursos y secciones.</p>
                            </div>
                        </div>
                    </div>
                    <div className="right-footer d-flex justify-content-center align-items-stretch">
                        <button disabled={this.state.buttonDisable} className="btn btn-primary" onClick={() => this.overlayShowHide()}>
                            Cerrar
                        </button>
                    </div>
                </div>
            )
        } else {
            return false;
        }

    }

    render() {
        return (
            <div>
                {this.productoExito()}
                {this.modalEditarCurso()}
                {this.overlayNuevoCurso()}
                <ListSecciones handle={this.state.handleObtenerSecciones} cursoNombre={this.state.producto.nombre} cursoId={this.state.cursoId} handleFuncion={this.handleObtenerSecciones} />
                <Navigation />
                <Fragment>
                    <div className="bg-light pt-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <label className="navigation-breadcrumb">Inicio > Enrollment > Producto</label>
                                    <div className="d-flex align-items-center">
                                        <Link to={"/productos"} className="btn btn-transparent mr-3 border"><i className="fas fa-arrow-left"></i></Link>
                                        <h5 className="m-0">Producto / {this.state.producto === false ? <LoaderSecondary></LoaderSecondary> : this.state.producto.nombre}</h5>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="navbarstiky bg-white mb-4 pb-3 pt-3">
                        <div className="container">
                            <div className="d-flex flex-column flex-xl-row flex-lg-row flex-md-row flex-sm-row justify-content-between">
                                {/* <button type="button" className="btn btn-primary" onClick={() => this.overlayShowHide()}>
                                    {this.props.textButton}
                                </button> */}
                                {/* <RightOverlay overlayContent={this.props.overlayContent} overlayShowHide={this.overlayShowHide} overlayShow={this.state.overlayShow} /> */}
                            </div>
                        </div>
                    </div>
                </Fragment>
                {this.renderProducto()}
            </div>
        )
    }
}

export default routeProductoId;

$('#tabIdiomas a').on('click', function (event) {
    event.preventDefault()
    $(this).tab('show')
})