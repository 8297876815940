import React, { Component, Fragment } from 'react';
import Navigation from '../components/Navigation/Navigation';
import SecondaryNavigation from '../components/SecondaryNavigation/SecondaryNavigation';
import Loader from '../components/Global/Loader';
import HeaderForm from '../components/Global/HeaderForm';
import $ from 'jquery';

class openai extends Component {
    constructor(props) {
        super(props);
        this.state = {
            motor: "davinci",
            rangeLargo: 60,
            rangeTemperature: 0.7,
            rangeTopP: 1,
            rangeFrecuencia: 0,
            rangeFrecuenciaP: 0,
            respuesta: null,
            log: false
        }

        this.handleFetch = this.handleFetch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.renderRespuesta = this.renderRespuesta.bind(this);
        this.handleRangeLargo = this.handleRangeLargo.bind(this);
        this.handleRangeTemperature = this.handleRangeTemperature.bind(this);
        this.handleRangeTopP = this.handleRangeTopP.bind(this);
        this.handleRangeFrecuencia = this.handleRangeFrecuencia.bind(this);
        this.handleRangeFrecuenciaP = this.handleRangeFrecuenciaP.bind(this);
        this.handleMotor = this.handleMotor.bind(this);
        this.postLog = this.postLog.bind(this);
        this.getTokens = this.getTokens.bind(this);
    }

    componentDidMount(){
        this.getTokens();
    }

    handleFetch() {
        this.setState({
            respuesta: true
        })
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer sk-K33idlkNf35fQeVLmF3w4rpzdhfMbe3zKXogkQbO");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("OpenAI-Organization", "org-A6maQ5GkKN02pM4QvsXaVebP");

        var raw = JSON.stringify({
            "prompt": this.state.texto.texto,
            "temperature": parseInt(this.state.rangeTemperature),
            "max_tokens": parseInt(this.state.rangeLargo),
            "top_p": parseInt(this.state.handleRangeTopP),
            "frequency_penalty": parseInt(this.state.rangeFrecuencia),
            "presence_penalty": parseInt(this.state.rangeFrecuenciaP)
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://api.openai.com/v1/engines/" + this.state.motor + "/completions", requestOptions)
            .then(response => response.json())
            .then(result => {
                var cantTokens = result.choices[0].text.length / 4;
                this.postLog(this.state.texto.texto, cantTokens, result.choices[0].text);
                this.setState({
                    respuesta: result.choices[0].text
                })
                
            })
            .catch(error => console.log('error', error));
    }

    getTokens() {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch( window.$UrlApiDev + "/logsOpenAI/" + localStorage.getItem("usuarioId"), requestOptions )
            .then(response => response.json())
            .then(result => {
                // console.log(result)
                var acumula = 0;
                for (let i = 0; i < result.length; i++) {
                    acumula = acumula + parseInt(result[i].tokens)
                }
                this.setState({
                    tokensConsumidos: acumula
                })
            })
            .catch(error => console.log('error', error));
    }

    postLog(respuesta, tokens, resultado) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "usuario": localStorage.getItem("usuarioId"),
            "consulta": respuesta,
            "tokens": tokens,
            "resultado": resultado
        });

        // console.log(raw)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(window.$UrlApiDev + "/logsOpenAI/", requestOptions)
            .then(response => response.json())
            .then(result => {
                this.setState({
                    log: result
                })
                this.getTokens();
            })
            .catch(error => console.log('error', error));
        
    }

    handleChange(e) {
        const { value, name } = e.target;
        this.setState({
            texto: {
                ...this.state.texto,
                [name]: value
            }
        })
    }

    handleSubmit() {
        this.toogleMenu();
        if (this.state.texto !== undefined) {
            this.handleFetch();
        }
    }

    handleRangeLargo(e) {
        const { value } = e.target;
        this.setState({
            rangeLargo: value
        })
        console.log(this.state)
    }

    handleRangeTemperature(e) {
        const { value } = e.target;
        this.setState({
            rangeTemperature: value
        })
    }

    handleRangeTopP(e) {
        const { value } = e.target;
        this.setState({
            rangeTopP: value
        })
    }
    handleRangeFrecuencia(e) {
        const { value } = e.target;
        this.setState({
            rangeFrecuencia: value
        })
    }
    handleRangeFrecuenciaP(e) {
        const { value } = e.target;
        this.setState({
            rangeFrecuenciaP: value
        })
    }

    handleMotor(e) {
        const { value } = e.target;
        this.setState({
            motor: value
        })
    }

    renderRespuesta() {

        if (this.state.respuesta === null) {
            return (
                <Fragment></Fragment>
            )
        } else if (this.state.respuesta === true) {
            return (
                <Loader />
            )
        } else {
            return (
                <Fragment>
                    <label>Respuesta</label>
                    <textarea className="form-control" name="respuesta" id="respuesta" rows="10" value={this.state.texto.texto + this.state.respuesta}></textarea>
                    <div className="d-flex justify-content-end mt-4">
                        <button className="btn btn-secondary" onClick={this.downloadTxtFile}>Descargar</button>
                    </div>
                </Fragment>
            )
        }
    }

    toogleMenu() {
        $("#wrapper").toggleClass("toggled");
        $('#menu-toggle').find('i').toggleClass('fa-arrow-right fa-arrow-left')
    }

    downloadTxtFile = () => {
        const element = document.createElement("a");
        const file = new Blob([document.getElementById('respuesta').value], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "respuesta.txt";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }


    render() {
        return (
            <Fragment>
                <Navigation />
                <SecondaryNavigation
                    title="Open Ai"
                />

                <div className="container mt-4">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">


                            <div className="d-flex" id="wrapper">

                                <div id="sidebar-wrapper">
                                    <div className="list-group">
                                        <h5 className="mb-4">Configuración</h5>
                                        <div className="form-group">
                                            <HeaderForm title="Largo" help="Corresponde al largo de la respuesta en cantidad de caracteres" position="right"></HeaderForm>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <input onChange={this.handleRangeLargo} type="range" className="custom-range col-5 mr-4" min="60" max="1024"></input>
                                                <input className="form-control" type="number" value={this.state.rangeLargo} />
                                            </div>
                                        </div>
                                        <div className="divider mb-4"></div>
                                        <div className="form-group">
                                            <HeaderForm title="Motor de inteligencia" help="Cada motor de inteligencia procesa la información según algoritmos diferentes." position="right"></HeaderForm>
                                            <select className="form-control" name="motor" onChange={this.handleMotor}>
                                                <option value="davinci">Davinci</option>
                                                <option value="ada">Ada</option>
                                                <option value="babbage">Babbage</option>
                                                <option value="curie">Curie</option>
                                            </select>
                                        </div>
                                        <div className="divider mb-4"></div>
                                        <div className="form-group">
                                            <HeaderForm title="Temperatura" help="Qué temperatura de muestreo utilizar. Valores más altos significan que el modelo asumirá más riesgos. Pruebe 0.9 para aplicaciones más creativas y 0 (muestreo argmax) para aquellas con una respuesta bien definida. Generalmente recomendamos modificar este o top_p pero no ambos. motor de inteligencia procesa la información según algoritmos diferentes." position="right"></HeaderForm>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <input onChange={this.handleRangeTemperature} type="range" className="custom-range col-5 mr-4" min="0.1" value={this.state.rangeTemperature} step="0.1" max="1"></input>
                                                <input className="form-control" type="number" value={this.state.rangeTemperature} />
                                            </div>
                                        </div>
                                        <div className="divider mb-4"></div>
                                        <div className="form-group">
                                            <HeaderForm title="Top P" help="Una alternativa al muestreo con temperatura, llamado muestreo de núcleo, donde el modelo considera los resultados de los tokens con masa de probabilidad top_p. Entonces, 0.1 significa que solo se consideran las fichas que comprenden la masa de probabilidad del 10% superior. Por lo general, recomendamos modificar esta o la temperatura, pero no ambas." position="right"></HeaderForm>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <input onChange={this.handleRangeTopP} type="range" className="custom-range col-5 mr-4" min="0" value={this.state.rangeTopP} step="0.1" max="1"></input>
                                                <input className="form-control" type="number" value={this.state.rangeTopP} />
                                            </div>
                                        </div>
                                        <div className="divider mb-4"></div>
                                        <div className="form-group">
                                            <HeaderForm title="Frecuencia de penalidad" help="Número entre 0 y 1 que penaliza a los nuevos tokens en función de su frecuencia existente en el texto hasta el momento. Disminuye la probabilidad de que el modelo repita la misma línea textualmente." position="right"></HeaderForm>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <input onChange={this.handleRangeFrecuencia} type="range" className="custom-range col-5 mr-4" min="0" value={this.state.rangeFrecuencia} step="0.1" max="1"></input>
                                                <input className="form-control" type="number" value={this.state.rangeFrecuencia} />
                                            </div>
                                        </div>
                                        <div className="divider mb-4"></div>
                                        <div className="form-group">
                                            <HeaderForm title="Presencia de penalidad" help="Número entre 0 y 1 que penaliza a los nuevos tokens en función de si aparecen en el texto hasta el momento. Aumenta la probabilidad del modelo de hablar sobre nuevos temas." position="right"></HeaderForm>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <input onChange={this.handleRangeFrecuenciaP} type="range" className="custom-range col-5 mr-4" min="0" value={this.state.rangeFrecuenciaP} step="0.1" max="1"></input>
                                                <input className="form-control" type="number" value={this.state.rangeFrecuenciaP} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="page-content-wrapper">
                                    <nav className="navbar navbar-expand-lg bg-transparent border-0 pl-3 pr-3 d-flex justify-content-between align-items-center mb-4">
                                        <div className="d-flex align-items-center">
                                            <button className="btn-round" onClick={this.toogleMenu} id="menu-toggle">
                                                <i className="fas fa-arrow-left"></i>
                                            </button>
                                            <h5 className="global-subtitle m-0">Contenido</h5>
                                        </div>
                                        {
                                            this.state.tokensConsumidos &&
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h5 className="mb-0">
                                                    <span className="mr-2">Tokens Consumidos</span>
                                                    <span className="badge badge-primary">
                                                        {this.state.tokensConsumidos}
                                                    </span>
                                                </h5>
                                                
                                            </div>
                                        }
                                        {
                                            this.state.texto &&
                                            <button className="btn btn-primary" onClick={this.handleSubmit}>
                                                Procesar
                                                </button>
                                        }
                                    </nav>
                                    <div className="container">
                                        <label>Ingresar texto</label>
                                        <textarea name="texto" className="form-control mb-4" rows="10" onChange={this.handleChange} placeholder="Ingresa texto" />
                                        {this.renderRespuesta()}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default openai;