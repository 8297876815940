import React, { Component, Fragment } from 'react';
import Tinymce from '../TextEditor/Tinymce';
import store from '../../store';

class SecondaryNavigationEmptyTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: '', // Corresponde al título de la barra de navegación
            textButton: '', // Corresponde al texto del botón que abre el RightOverlay
        }
    }

    handleOpen(e) {
        e.preventDefault();
        store.dispatch({
            type: "OPEN_TEMPLATE",
            openTextEditor: true
        })
    }

    render() {

        return (
            <Fragment>
                <Tinymce></Tinymce>
                <div className="bg-light pt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <label className="navigation-breadcrumb">Inicio > {this.props.title}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="navbarstiky sticky-top bg-white mb-4 pb-3 pt-3">
                    <div className="container">
                        <div className="d-flex flex-column flex-xl-row flex-lg-row flex-md-row flex-sm-row justify-content-between">
                            <h5>{this.props.title}</h5>
                            <button onClick={this.handleOpen} className="btn btn-primary">Añadir Template</button>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default SecondaryNavigationEmptyTemplate;