import React, {Component} from 'react';
import Connect from '../../models/Connect';
import LoaderSecondary from '../Global/LoaderSecondary';
import store from '../../store';

class SelectClient extends Component{

    constructor(props){
        super(props);
            this.state = {
                isloaded: false,
                clientesSelect : '',
                status: '',
            }
            this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount(){
        let clientesSelect = new Connect('clientes', 'GET');
        clientesSelect.start().then( cliente => {
            this.setState({
                isloaded: true,
                clientesSelect : cliente.body,
                status: cliente.status,
            })
            let valor = document.getElementById('selectClientOption').value;
           
            store.dispatch({
                type: "SELECT_CLIENT",
                cliente: valor
            })
            store.dispatch({
                type: "LIST_CLIENT",
                listClientes: cliente.body
            })
        });
    }
    
    handleChange(){
        let valor = document.getElementById('selectClientOption').value;
        store.dispatch({
            type: "SELECT_CLIENT",
            cliente: valor
        })
        // console.log(valor);
    }

    render(){
        let { isloaded, clientesSelect } = this.state;
        if( isloaded === false ){
            return ( 
                <React.Fragment>
                    <p className="mr-3">Cliente: </p>
                    <LoaderSecondary></LoaderSecondary>
                </React.Fragment>
             );
        } else{
            return(
                <React.Fragment>
                <p className="mr-3">Cliente: </p>
                <div className="btn-group selectdiv" role="group">
                    <select id="selectClientOption" className="form-control select-primary" onChange={this.handleChange}>
                        {clientesSelect.map(cliente => 
                            <option key={cliente.id} value={cliente.id}>{cliente.dominio}</option>
                        )}
                    </select>
                </div>
                </React.Fragment>
            );
        }
    }

}
export default SelectClient;