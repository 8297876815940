import React, { Component } from 'react';
import Navigation from '../components/Navigation/Navigation';
import CertificadosForm from '../components/Certificados/CertificadosForm'
import SecondaryNavigationEmpty from '../components/SecondaryNavigation/SecondaryNavigationEmpty';
import ListCertificados from '../components/Certificados/ListCertificados';
import TextEditor from '../components/TextEditor/Tinymce';

class routeListCertificados extends Component{
  
  render(){
    return(
      <React.Fragment>
        <TextEditor></TextEditor>
        <Navigation/>
        <SecondaryNavigationEmpty
          title="Todos los certificados"
          textButton="Crear certificado"
          overlayContent={CertificadosForm}
        />
        <ListCertificados/>
      </React.Fragment>
    )
  }
  
}

export default routeListCertificados;
