import React, { Component } from 'react'
import LoadingSecondary from '../Global/LoaderSecondary'
import store from '../../store';
import { connect } from 'react-redux';

class SelectTemplate extends Component {

    constructor(props) {
        super(props)
        this.state = {
            templates: [],
            loading: false
        }
        this.handleOpen = this.handleOpen.bind(this)
    }

    componentWillMount() {
        this.fetchData();
    }

    handleOpen(e) {
        e.preventDefault();
        store.dispatch({
            type: "OPEN_TEMPLATE",
            openTextEditor: true
        })
    }

    componentWillReceiveProps() {
        this.fetchData();
    }

    fetchData() {

        this.setState({
            loading: true,
            templates: []
        });

        var myHeaders = new Headers();
        myHeaders.append("Auth-Token", localStorage.getItem('token'));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(window.$UrlApiDev + "/templates/", requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result)
                this.setState({
                    templates: result,
                    loading: false
                });

            })
            .catch(error => console.log('error', error));
    }

    render() {
        if (this.state.loading === true) {
            return (
                <LoadingSecondary texto="Cargando Templates"></LoadingSecondary>
            )
        } else {
            return (
                <div className="d-flex justify-content-between align-items-center mb-2">
                    {this.props.template.length === 0 ?
                        <div className="form-group select-templtate">
                            <label htmlFor="id_template">Template</label>

                            <select value={this.props.selected} id="id_template" type="text" className="form-control" name="id_template" onChange={this.props.change} required>
                                <option value="">Selecciona el template</option>
                                {this.state.templates.map(item => {
                                    return <option
                                        key={item.id}
                                        value={item.id}>{item.id} - {item.nombre}
                                    </option>
                                })}
                            </select>
                            <div className="invalid-feedback">Selecciona un template</div>
                            <div className="valid-feedback">Excelente!</div>
                        </div>
                        :
                        <div className="form-group">
                            <label htmlFor="id_template">Template</label>
                            <select value={this.props.template[this.props.template.length - 1]} id="id_template" type="text" className="form-control" name="id_template" onChange={this.props.change} required>
                                <option value="">Selecciona el template</option>
                                {this.state.templates.map(item => {
                                    return <option
                                        key={item.id}
                                        value={item.id}>{item.id} - {item.nombre}
                                    </option>
                                })}
                            </select>
                            <div className="invalid-feedback">Selecciona un template</div>
                            <div className="valid-feedback">Excelente!</div>
                        </div>
                    }
                    {this.props.selected ? false : <span>O</span>}
                    {this.props.selected ? false : <button onClick={this.handleOpen} className="btn btn-primary">Crea un template</button>}
                </div>
            )
        }
    }
}

const mapStateToProps = state => ({
    template: state.template
})

export default connect(mapStateToProps)(SelectTemplate)