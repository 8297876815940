import React, { Component } from 'react';
import EditTemplate from '../components/templates/EditTemplate';

class editTemplates extends Component {

  render() {
    return (
      <React.Fragment>
        <EditTemplate id={this.props.match.params.id}/>
      </React.Fragment>
    )
  }

}

export default editTemplates;
