import React, { Component } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar'
import Navigation from '../components/Navigation/Navigation';
import SecondaryNavigationReportes from '../components/SecondaryNavigation/SecondaryNavigationReportes';
import { ExcelRenderer, OutTable } from 'react-excel-renderer';
import { Animated } from "react-animated-css";

// const mySweetTheme = {
//   rows: {
//     height: '60px'
//   },
// }

class routeReportes extends Component {

  constructor(props) {
    super(props)
    this.state = {
      rows: null,
      procesado: null
    }
    this.handleSubirArchivo = this.handleSubirArchivo.bind(this);
    this.loadingProcesando = this.loadingProcesando.bind(this);
    this.handleClickCerrar = this.handleClickCerrar.bind(this);
    this.procesarReportes = this.procesarReportes.bind(this);
  }

  handleSubirArchivo(archivo, endpoint) {
    ExcelRenderer(archivo, (err, resp) => {
      if (err) {
        console.log(err);
      }
      else {
        // console.log(resp.rows)
        this.setState({
          cols: resp.cols,
          rows: resp.rows,
          endpoint: endpoint
        });
      }
    });
  }

  procesarReportes() {

    const fetchUrl = (valor) => {
      switch (valor) {
        case "productos":
          return "https://reportingdev.capabiliaserver.com/api/v1/d_Productos/"
          //return "http://local.capreportingapi.com/api/v1/d_Productos/"
          break;

        case "usuarios":
          return "https://reportingdev.capabiliaserver.com/api/v1/d_Usuarios/"
          //return "http://local.capreportingapi.com/api/v1/d_Usuarios/"
          break;

        case "transacciones":
          return "https://reportingdev.capabiliaserver.com/api/v1/d_Transacciones/"
          //return "http://local.capreportingapi.com/api/v1/d_Transacciones/"
          break;

        default:
          return "https://reportingdev.capabiliaserver.com/api/v1/d_Productos/"
          break;
      }
    }

    const body = (data, endpoint) => {
      let arr = [];
      switch (endpoint) {
        case "productos":
          
          for (let i = 0; i < data.length; i++) {
            if (i > 0) {
              arr.push({
                "id_product": data[i][0],
                "program_name": data[i][1],
                "type_product": data[i][2]
              })
            }
          }

          return arr
          break;
        case "transacciones":
          
          for (let i = 0; i < data.length; i++) {
            if (i > 0) {
              arr.push({
                "id_transaction": data[i][0],
                "fecha": data[i][1],
                "id_cliente": data[i][2],
                "id_product": data[i][3],
                "precio_de_lista": data[i][4],
                "precio_pagado_eur": data[i][5],
                "precio_de_lista_2": data[i][6],
                "precio_pagado": data[i][7],
                "tipo_de_cambio": data[i][8],
                "source": data[i][9],
                "campaign": data[i][10],
                "medium": data[i][11],
                "fuente_social": data[i][12]
              })
            }
          }

          return arr
          break;
        case "usuarios":

          for (let i = 0; i < data.length; i++) {
            if (i > 0) {
              arr.push({
                "id_cliente" : data[i][0],
                "email" : data[i][1],
                "first_name" : data[i][2],
                "last_name" : data[i][3],
                "birthdate" : data[i][4],
                "mkt_emails_opened" : data[i][5],
                "postal_code" : data[i][6],
                "time_of_last_session" : data[i][7],
                "time_of_first_session" : data[i][8],
                "num_of_times_contacted" : data[i][9],
                "num_of_sales_activities" : data[i][10],
                "first_conversion_date" : data[i][11],
                "pref_lang" : data[i][12],
                "last_modified_date" : data[i][13],
                "number_of_sessions" : data[i][14],
                "country" : data[i][15],
                "phone_number" : data[i][16],
                "mkt_emails_clicked" : data[i][17],
                "last_mkt_email_open_date" : data[i][18],
                "last_mkt_email_send_date" : data[i][19],
                "recent_conversion" : data[i][20],
                "lifecycle_stage" : data[i][21],
                "contact_priority" : data[i][22],
                "privacy_police" : data[i][23],
                "opt_out" : data[i][24]
              })
            }
          }

          return arr
          break;
        default:
          break;
      }
    }

    let result = body(this.state.rows, this.state.endpoint)
    let fechi = fetchUrl(this.state.endpoint);

    var raw = {
      "tipo": this.state.endpoint,
      "datos": result
    };

    var myHeaders = new Headers();
    myHeaders.append("Auth-Token", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjM0IiwidXN1YXJpb19lbWFpbCI6ImFkbWluIiwidXN1YXJpb190aXBvIjoiMCJ9.rIh9srE3487Er8UCWLB9ovOTkjqsUckzKcCVM9b3GB4");
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(raw)
    };

    console.log(fechi)
    console.log(raw)

    this.setState({
      procesado: false
    })

    fetch(fechi, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        this.setState({
          procesado: true
        })
      })
      .catch(error => {
        console.log(error)
      });


  }

  loadingProcesando() {
    if (this.state.procesado === false) {
      return (
        <div className="loading-status">
          <div className="loading-status-container">
            <h4 className="global-title-1">Procesando Reportes</h4>
            <p>No cierres esta página</p>
            <ProgressBar animated variant="warning" now={this.state.count} />

          </div>
        </div>
      )
    } else if (this.state.procesado === true) {
      return (
        <div className="loading-status">
          <div className="loading-status-container">
            <h4 className="global-title-1">Procesado con éxito!</h4>
            <p>Ya puedes continuar</p>
            <ProgressBar animated variant="success" now={100} />
            <button onClick={this.handleClickCerrar} className="btn btn-primary mt-4">Cerrar</button>
          </div>
        </div>
      )
    } else {
      return false
    }
  }

  handleClickCerrar() {
    this.setState({
      procesado: null,
      rows: null,
      count: 0
    })
  }

  render() {
    if (this.state.rows === null) {
      return (
        <div>
          {this.loadingProcesando()}
          <Navigation />
          <SecondaryNavigationReportes
            title={`Carga de Reportes`}
            textButton="Cargar archivo"
            descarga={true}
            handleSubirArchivo={this.handleSubirArchivo}
          />
        </div>
      )
    } else {
      return (
        <div>
          <Navigation />
          <SecondaryNavigationReportes
            title={`Carga de Reportes`}
            textButton="Cargar archivo"
            descarga={true}
            handleSubirArchivo={this.handleSubirArchivo}
          />
          {this.loadingProcesando()}
          <Animated animationIn="slideInUp" animationOut="fadeOut" animationInDuration={380} isVisible={true}>
            <div className="container">
              <div className="row">
                <div className="col-12 d-flex justify-content-end">
                  <button className="btn btn-primary mt-5 mb-3" onClick={this.procesarReportes}>Procesar Reportes</button>
                </div>
                <div className="col-lg-12">
                  <OutTable data={this.state.rows} columns={this.state.cols} tableClassName="table table-striped table-bordered" tableHeaderRowClass="heading" />
                </div>
              </div>
            </div>
          </Animated>
        </div>
      )

    }

  }

}

export default routeReportes;