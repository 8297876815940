import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

class PrivateRoute extends Component {

    render() {
        if (localStorage.getItem('token') === 0 || localStorage.getItem('token') === undefined || localStorage.getItem('token') === null) {
            return (
                <Redirect to="/login" />
            )
        } else {
            return (
                <Route push={true} exact path={this.props.path} component={this.props.component} />
            );
        }

    }
}

export default PrivateRoute;