import React, { Component } from 'react';
import HeaderForm from '../Global/HeaderForm';
import IconLarge from '../Global/IconLarge';


class AddSeccionManual extends Component {
    constructor(props) {
        super(props);

        this.state = {
            buttonDisable: false,
            validation: "needs-validation",
            data: {},
            estado: false,
            fecha: "",
            fechaTrim: "",
            cursoNombre: this.props.cursoId.nombre,
            nombreSeccion: this.props.cursoId.nombre,
            nombreSeccionInterno: this.props.cursoId.nombre.replace(/ /g, ""),
            cvaL: "",
            cvaC: "",
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleInput = this.handleInput.bind(this);
    }

    handleClose() {
        this.props.handleFuncion()
    }

    handleInput(e) {
        const { value, name } = e.target;
        if ([name][0] === "cva") {
            if (value === "0") {
                this.setState({
                    cvaL: "",
                    cvaC: "",
                    nombreSeccion: this.state.cursoNombre + " " + this.state.fecha,
                    nombreSeccionInterno: this.state.cursoNombre.replace(/ /g, "") + this.state.fechaTrim.replace(/ /g, ""),
                })
            } else {
                this.setState({
                    cvaL: "CERTIFICADO DE VERIFICACIÓN APROBADO ",
                    cvaC: "VAL",
                })
                if(this.state.cvaL === ""){
                    this.setState({
                        nombreSeccion: "CERTIFICADO DE VERIFICACIÓN APROBADO " + this.state.cursoNombre + " " + this.state.fecha,
                        nombreSeccionInterno: "VAL" + this.state.cursoNombre.replace(/ /g, "")  + this.state.fecha.replace(/ /g, ""),    
                    })
                }
            }
        }

        if ([name][0] === "seccion_inicio") {
            var date = value;
            var arr1 = date.split('-');
            var fecha;
            var mes;

            switch (arr1[1]) {
                case '01':
                    mes = "Enero"
                    break;
                case '02':
                    mes = "Febrero"
                    break;
                case '03':
                    mes = "Marzo"
                    break;
                case '04':
                    mes = "Abril"
                    break;
                case '05':
                    mes = "Mayo"
                    break;
                case '06':
                    mes = "Junio"
                    break;
                case '07':
                    mes = "Julio"
                    break;
                case '08':
                    mes = "Agosto"
                    break;
                case '09':
                    mes = "Septiembre"
                    break;
                case '10':
                    mes = "Octubre"
                    break;
                case '11':
                    mes = "Noviembre"
                    break;
                case '12':
                    mes = "Diciembre"
                    break;

                default:
                    break;
            }
            fecha = arr1[2] + " " + mes + " " + arr1[0]
            var fechaCruda = arr1[2] + " " + mes + " " + arr1[0]
            fecha = this.props.cursoId.nombre + " " + fecha
            var fechaTrim = fecha.replace(/\s/g, '')
            this.setState({
                fecha: fechaCruda,
                fechaTrim: fechaCruda.replace(/\s/g, ''),
                nombreSeccion: fecha,
                nombreSeccionInterno: this.state.cvaL + fechaTrim,
                cursoNombre: this.state.cvaC + this.props.cursoId.nombre
            })
        }

        this.setState({
            data: {
                ...this.state.data,
                [name]: value
            },
            [name]: value,
        })

    }

    handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        var fechaUp = new Date(this.state.data.seccion_inicio);
        fechaUp = fechaUp.toLocaleDateString("es-AR")

        this.props.secciones.map(item => {
            var fechaInicio = new Date(item.fechaInicio);
            fechaInicio = fechaInicio.toLocaleDateString("es-AR")
            // console.log(fechaUp)
            // console.log(fechaInicio)

            if(fechaUp === fechaInicio){
                console.log(fechaUp)
                console.log(fechaInicio)
                alert("No puedes crear una sección en la misma fecha.")
                return false;
            }
            
        })
        return false;

        if (this.handleValidation()) {
            this.setState({
                buttonDisable: true
            })

            var myHeaders = new Headers();
            myHeaders.append("Auth-Token", 'zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs');
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify(
                {
                    "nombre": this.state.data.seccion_nombre,
                    "fechaInicio": this.state.data.seccion_inicio,
                    "fechaFin": this.state.data.seccion_fin
                }
            );

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(window.$UrlOctavio + "/cursos/" + this.props.cursoId + "/secciones/", requestOptions)
                .then(r => r.json()
                    .then(data => ({
                        status: r.status,
                        body: data
                    })))
                .then(obj => {
                    if (obj.status !== 200) {
                        this.setState({
                            estado: obj.status,
                            mensaje: obj.body,
                            buttonDisable: false
                        })
                    } else {
                        this.setState({
                            estado: obj.status,
                            mensaje: obj.body,
                            buttonDisable: false
                        })
                        this.handleReRender();
                    }

                })
                .catch(error => console.log('error', error));
        } else {
            return false;
        }
    }

    handleValidation() {
        this.setState({
            validation: "needs-validation was-validated",
        });
        if (!this.state.data.seccion_nombre || !this.state.data.seccion_inicio || !this.state.data.seccion_fin) {
            return false;
        } else {
            return true;
        }
    }

    render() {
        if (this.props.show) {
            return (
                <div className="right-overlay zindextop">
                    <div className="right-content bg-white shadow-lg">
                        <div className="right-header">
                            <div className="row d-flex justify-content-between align-items-center">
                                <div>
                                    <h6>Añadir nueva sección</h6>
                                </div>
                                <div>
                                    <button className="btn btn-close" onClick={() => this.handleClose()}>
                                        <i className="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="right-body bg-white">
                            <div className="row">
                                <div className="col-lg-12">

                                    {this.state.estado ? false :

                                        <form onSubmit={this.handleSubmit} className={this.state.validation} noValidate>

                                            <HeaderForm title="Información de la sección" content="asd" position="bottom"></HeaderForm>

                                            <div className="form-group">
                                                <label htmlFor="seccion_nombre">Nombre de la sección</label>
                                                <input disabled id="seccion_nombre" type="text" className="form-control" name="seccion_nombre" placeholder="Nombre de la sección" onChange={this.handleInput} required value={this.state.nombreSeccion}></input>
                                                <div className="invalid-feedback">Ingresa el nombre</div>
                                                <div className="valid-feedback">Excelente!</div>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="seccion_nombre_interno">Nombre interno</label>
                                                <input id="seccion_nombre_interno" type="text" className="form-control" name="seccion_nombre_interno" placeholder="Nombre de la sección" disabled="disabled" value={this.state.nombreSeccionInterno}></input>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="seccion_inicio">Fecha de inicio</label>
                                                <input id="seccion_inicio" type="date" className="form-control" name="seccion_inicio" onChange={this.handleInput} required></input>
                                                <div className="invalid-feedback">Ingresa la fecha de inicio</div>
                                                <div className="valid-feedback">Excelente!</div>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="seccion_fin">Fecha de fin</label>
                                                <input id="seccion_fin" type="date" className="form-control" name="seccion_fin" onChange={this.handleInput} required></input>
                                                <div className="invalid-feedback">Ingresa la fecha de fin</div>
                                                <div className="valid-feedback">Excelente!</div>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="cva">Certificado de verificación aprobado</label>
                                                <br></br>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input id="si" type="radio" name="cva" onChange={this.handleInput} value="1" className="custom-control-input" required />
                                                    <label className="custom-control-label" htmlFor="si">Si</label>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input id="no" type="radio" name="cva" onChange={this.handleInput} value="0" className="custom-control-input" required />
                                                    <label className="custom-control-label" htmlFor="no">No</label>
                                                </div>
                                            </div>

                                        </form>

                                    }

                                    {this.state.estado === 400 &&
                                        <div>
                                            <IconLarge icon="fa-close" size="4" color="text-danger" animation="shake" />
                                            <p><b>Error</b></p>
                                            <p>Estado: {this.state.estado} <br></br></p>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="right-footer d-flex justify-content-center align-items-center">
                            <button className="btn btn-primary mr-2" onClick={(e) => this.handleSubmit(e)}>
                                Añadir
                            </button>
                            <button disabled={this.state.buttonDisable} className="btn btn-secondary" onClick={() => this.handleClose()}>
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            );
        } else {
            return false;
        }

    }
}

export default AddSeccionManual;