import React, { Component } from 'react';
import { Animated } from "react-animated-css";
import Loader from '../Global/Loader';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import store from '../../store';

const mySweetTheme = {
  rows: {
    height: '60px'
  },
}

const columns = [
  {
    name: 'ID',
    selector: 'id',
    sortable: true,
    width: '90px',
    cell: row => <span className="badge badge-secondary">{row.id}</span>,
    ignoreRowClick: true,
  },
  {
    name: 'Idioma',
    selector: 'idioma',
    sortable: true,
    width: '100px',
    ignoreRowClick: true,
  },
  {
    name: 'Nombre del template',
    selector: 'nombre',
    ignoreRowClick: true,
    width: '300px',
    sortable: true,
  },
  {
    name: 'Ancho',
    selector: 'width',
    ignoreRowClick: true,
    sortable: true,
  },
  {
    name: 'Alto',
    selector: 'height',
    ignoreRowClick: true,
    sortable: true,
  },
  {
    name: 'Acciones',
    wrap: false,
    ignoreRowClick: true,
    cell: row =>
      <Link to={`/templates/${row.id}`}>
        <button className="btn btn-secondary mr-3">Editar</button>
      </Link>
  }
];

class ListTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      isLoaded: false,
      rerender: [],
      count: 0,
      overlayContent: '', // Corresponde al contenido que lleva adentro del RightOverlay
      overlayShow: false // Es el estado del RightOverlay
    }
    store.subscribe(() => {
      this.setState({
        rerender: store.getState().rerender
      });
    })
    this.handlePagination = this.handlePagination.bind(this)
  }

  componentWillUpdate(prevProps, prevState) {
    const ant = this.state.rerender;
    if (ant !== prevState.rerender) {
      if (this.state.rerender !== null) {
        this.Fetch();
      }
    }
  }

  componentDidMount() {
    this.Fetch();
  }

  Fetch() {

    var myHeaders = new Headers();
    myHeaders.append("Auth-Token", localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch( window.$UrlApiDev + "/templates/", requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)

        this.setState({
          templates: result,
          isLoaded: true,
          count : result.length
        })
      })
      .catch(error => console.log('error', error));

  }

  handlePagination() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }


  render() {
    var { isLoaded, templates } = this.state;
    if (!isLoaded) {
      return (
        <Loader/>
      )
    } else {
      return (
        <div>
          <Animated animationIn="slideInUp" animationOut="fadeOut" animationInDuration={380} isVisible={true}>
            <div className="container">

              <div className="row">
                <div className="col-12 text-right">
                  <h6 className="ml-4 mr-2"><span className="badge badge-primary mr-2">{this.state.count}</span> Resultados</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">

                  <DataTable
                    noHeader={true}
                    columns={columns}
                    title={false}
                    data={templates}
                    customTheme={mySweetTheme}
                    pagination={true}
                    paginationPerPage={20}
                    highlightOnHover={true}
                    striped={true}
                    pointerOnHover={false}
                    overflowY={false}
                    onChangePage={this.handlePagination}
                    search
                  />

                </div>
              </div>
            </div>
          </Animated>
        </div>
      )
    }

  }
}

export default ListTemplates;