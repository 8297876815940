import React, { Component } from 'react';
import { Animated } from "react-animated-css";
import Loader from '../Global/Loader';
import DataTable from 'react-data-table-component';
import store from '../../store';
import RightOverlay from '../RightOverlay/RightOverlay'
import UsuariosEdit from './UsuariosEdit';

const mySweetTheme = {
  rows: {
    height: '60px'
  },
}

class ListClientes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuarios: [],
      isLoaded: false,
      rerender: [],
      count: 0,
      overlayContent: '', // Corresponde al contenido que lleva adentro del RightOverlay
      overlayShow: false, // Es el estado del RightOverlay
      overlayEditShow: false, // Es el estado del RightOverlay
      IdEdit: null
    }
    store.subscribe(() => {
      this.setState({
        rerender: store.getState().rerender
      });
    })
    this.handlePagination = this.handlePagination.bind(this)
    this.overlayShowHide = this.overlayShowHide.bind(this)

  }

  handleEdit(id) {
    console.log()
    this.setState({
      overlayEditShow: true,
      IdEdit: id
    })
  }

  overlayShowHide(){
    this.setState(prevState => ({
      overlayEditShow: !prevState.overlayEditShow,
      IdEdit: null
    }));
  }

  componentWillUpdate(prevProps, prevState) {
    const ant = this.state.rerender;
    if (ant !== prevState.rerender) {
      if (this.state.rerender !== null) {
        this.Fetch();
      }
    }
  }

  componentDidMount() {
    this.Fetch();
  }

  async Fetch() {
    var myHeaders = new Headers();
    myHeaders.append("Auth-Token", localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(window.$UrlApiDev + "/usuarios/", requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)

        this.setState({
          isLoaded: true,
          clientes : result,
          count : result.length
        })
      })
      .catch(error => console.log('error', error));
  }

  handlePagination() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }


  render() {

    const columns = [
      {
        name: 'ID',
        selector: 'id',
        sortable: true,
        width: '60px',
        cell: row => <span className="badge badge-secondary">{row.id}</span>,
        ignoreRowClick: true,
      },
      {
        name: 'Nombre',
        selector: 'usuario_nombre',
        sortable: true,
        width: '160px',
        ignoreRowClick: true,
      },
      {
        name: 'Apellido',
        selector: 'usuario_apellido',
        width: '160px',
        sortable: true,
        ignoreRowClick: true,
      },
      {
        name: 'Email',
        selector: 'usuario_email',
        sortable: true,
        width: '300px',
        ignoreRowClick: true,
      },
    
      {
        name: 'Tipo de usuario',
        selector: 'usuario_tipo',
        ignoreRowClick: true,
        sortable: true,
        cell: row => {
          if (row.usuario_tipo === "1") {
            return <span className="badge badge-secondary">Administrador</span>;
          } else {
            return <span className="badge badge-secondary">Autor</span>;
          }
        }
      },
    
      {
        name: 'Estado',
        selector: 'usuario_estado',
        sortable: true,
        ignoreRowClick: true,
        cell: row => {
          if (row.usuario_estado === "1") {
            return <span className="badge badge-success">Activo</span>;
          } else {
            return <span className="badge badge-danger">Inactivo</span>;
          }
        }
      },
      {
        name: 'Acciones',
        wrap: false,
        ignoreRowClick: true,
        cell: row =>
          <button onClick={() => this.handleEdit(row.id)} className="btn btn-secondary mr-3">Editar</button>
      }
    ];

    var { isLoaded, clientes } = this.state;
    if (!isLoaded) {
      return (
        <Loader />
      )
    } else {
      return (
        <div>
          <RightOverlay id={this.state.IdEdit} overlayContent={UsuariosEdit} overlayShowHide={this.overlayShowHide} overlayShow={this.state.overlayEditShow}/>
          <Animated animationIn="slideInUp" animationOut="fadeOut" animationInDuration={380} isVisible={true}>
            <div className="container">

              <div className="row">
                <div className="col-12 text-right">
                  <h6 className="ml-4 mr-2"><span className="badge badge-primary mr-2">{this.state.count}</span> Resultados</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">

                  <DataTable
                    noHeader={true}
                    columns={columns}
                    title={false}
                    data={clientes}
                    customTheme={mySweetTheme}
                    pagination={true}
                    paginationPerPage={20}
                    highlightOnHover={true}
                    striped={true}
                    pointerOnHover={false}
                    overflowY={false}
                    onChangePage={this.handlePagination}
                  />

                </div>
              </div>
            </div>
          </Animated>
        </div>
      )
    }

  }
}

export default ListClientes;