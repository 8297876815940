import React, { Component, Fragment } from 'react';
import HeaderForm from '../Global/HeaderForm';
import Loader from '../Global/Loader';

class Asistencia extends Component {

    constructor(props) {
        super(props);
        this.state = {
            overlayShowHide: "",
            buttonDisable: false,
            validation: "needs-validation",
            certificacionlist: null
        };
        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.sendForm = this.sendForm.bind(this);
        this.handleSelectCertificacion = this.handleSelectCertificacion.bind(this);
    }

    componentDidMount() {
        var myHeaders = new Headers();
        myHeaders.append("Auth-Token", localStorage.getItem('token'));
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(window.$UrlApiDev + "/certificacion/", requestOptions)
            .then(response => response.json())
            .then(result => {
                this.setState({
                    certificacionlist: result
                })
            })
            .catch(error => console.log('error', error));
    }

    handleInput(e) {
        let fileObj = e.target.files[0];
        this.setState({
            archivo: fileObj
        })
    }

    handleSelectCertificacion(e){
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            validation: "needs-validation was-validated",
        });

        if (this.handleValidation() === true) {
            this.sendForm();
        }
    }

    handleValidation() {
        if (!this.state.archivo, !this.state.certificacion) {
            return false;
        } else {
            return true;
        }
    }

    sendForm() {
        this.props.handleSubirArchivo(this.state.archivo, this.state.certificacion);
        this.props.overlayShowHide()
    }

    buttonsAction() {
        if (this.state.buttonDisable === false) {
            return (
                <div className="right-footer d-flex justify-content-center align-items-stretch">
                    <button disabled={this.state.buttonDisable} className="btn btn-primary mr-2" onClick={() => this.handleSubmit}>
                        Subir archivo
                    </button>
                    <button disabled={this.state.buttonDisable} className="btn btn-secondary" onClick={() => this.props.overlayShowHide()}>
                        Cancelar
                    </button>
                </div>
            )
        } else {
            return (
                <div className="right-footer d-flex justify-content-center align-items-stretch">
                    <button disabled={this.state.buttonDisable} className="btn btn-primary mr-2" onClick={() => this.handleButtonShow()}>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                         Subiendo...
                    </button>
                </div>
            )
        }
    }

    render() {
        return (
            <Fragment>

                <form onSubmit={this.handleSubmit} className={this.state.validation} noValidate>
                    <div className="right-content bg-white shadow-lg">
                        <div className="right-header">
                            <div className="row d-flex justify-content-between align-items-center">
                                <div>
                                    <h6>Añadir nuevo certificado</h6>
                                </div>
                                <div>
                                    <button className="btn btn-close" onClick={() => this.props.overlayShowHide()}>
                                        <i className="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="right-body bg-white">
                            <div className="row">
                                <div className="col-lg-12">

                                    <HeaderForm title="Selecciona el archivo" help="Selecciona el arvhivo de Excel respetando las columnas como el archivo modelo." position="bottom"></HeaderForm>

                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Subir</span>
                                        </div>
                                        <div className="custom-file">
                                            <input type="file" name="archivo" className="custom-file-input" onChange={this.handleInput} required />
                                            <label className="custom-file-label">Selecciona el archivo</label>
                                        </div>
                                    </div>


                                    {
                                        this.state.certificacionlist !== null ?
                                        <div>
                                            <label>Certificación</label>
                                            <select className="form-control" name="certificacion" id="" onChange={this.handleSelectCertificacion} required>
                                                <option value="">Selecciona una certificación</option>
                                                { this.state.certificacionlist.map(item => {
                                                    return <option key={item.id} value={item.id}>
                                                        ID: {item.id} | ID Producto: {item.id_producto} | Nombre producto: {item.nombre_producto} | Tipo: {item.tipo}
                                                    </option>
                                                }) }
                                            </select>
                                        </div>
                                        :
                                        <Loader></Loader>
                                    }



                                </div>
                            </div>
                        </div>
                        {this.buttonsAction()}
                    </div>
                </form>
            </Fragment>
        )
    }
}


export default Asistencia;