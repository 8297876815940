import React, {Component} from 'react';

class IconLarge extends Component{

    render(){
        return(
            <div className={`icon-large animated ${this.props.animation}`}>
                <i className={`fa ${this.props.icon} fa-${this.props.size}x ${this.props.color}`}></i>
            </div>
        )
    }
    
}

export default IconLarge;