import React, { Component } from 'react';
import HeaderForm from '../Global/HeaderForm';

class AddSeccionLote extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rango: "0"
        }

        this.handleClose = this.handleClose.bind(this);
        this.handleRango = this.handleRango.bind(this);
    }

    componentWillMount() {
        console.log(this.props)
    }

    handleClose() {
        this.props.handleFuncion()
    }

    handleRango(e) {
        const { value } = e.target;
        this.setState({
            rango: value
        })
    }

    render() {
        if (this.props.show) {
            return (
                <div className="right-overlay zindextop">
                    <div className="right-content bg-white shadow-lg">
                        <div className="right-header">
                            <div className="row d-flex justify-content-between align-items-center">
                                <div>
                                    <h6>Añadir secciones por lote</h6>
                                </div>
                                <div>
                                    <button className="btn btn-close" onClick={() => this.handleClose()}>
                                        <i className="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="right-body bg-white">
                            <div className="row">
                                <div className="col-lg-12">

                                    <HeaderForm title="Añadir secciones por lote" position="bottom" help="Se añadiran secciones masivamente a partir de los valores proporcionados en este formulario"></HeaderForm>

                                    <div className="form-group">
                                        <label htmlFor="usuario_nombre">Nombre de la sección</label>
                                        <input id="usuario_nombre" type="text" className="form-control" name="usuario_nombre" placeholder="Nombre de la sección" onChange={this.handleInput} required></input>
                                        <div className="invalid-feedback">Ingresa el nombre</div>
                                        <div className="valid-feedback">Excelente!</div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="usuario_nombre">Fecha de cursado</label>
                                        <input id="usuario_nombre" type="date" className="form-control" name="usuario_nombre" onChange={this.handleInput} required></input>
                                        <div className="invalid-feedback">Ingresa la fecha de cursado</div>
                                        <div className="valid-feedback">Excelente!</div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="usuario_nombre">Cantidad de repeticiones</label>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <input onChange={this.handleRango} type="range" className="custom-range col-8 mr-4" min="0" max="24" value={this.state.rango}></input>
                                            <input className="form-control" type="number" value={this.state.rango} />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="usuario_nombre">Certificado de verificación aprobado</label>
                                        <br></br>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input id="si" checked="checked" type="radio" name="usuario_estado" onChange={this.handleInput} value="1" className="custom-control-input" required />
                                            <label className="custom-control-label" htmlFor="si">Si</label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input id="no" type="radio" name="usuario_estado" onChange={this.handleInput} value="1" className="custom-control-input" required />
                                            <label className="custom-control-label" htmlFor="no">No</label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="right-footer d-flex justify-content-center align-items-center">
                            <button className="btn btn-primary mr-2" onClick={() => this.handleSubmit}>
                                Añadir secciones por lote
                            </button>
                            <button disabled={this.state.buttonDisable} className="btn btn-secondary" onClick={() => this.handleClose()}>
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            );
        } else {
            return false;
        }

    }
}

export default AddSeccionLote;