import ConnectConfig from './ConnectConfig';

class Connect{
    constructor(url, method, body){
        this.url = url;
        this.method = method;
        this.body = body;
    }
    start(){
        return new Promise((resolve, reject) => {
            if(this.body === null){
                fetch( ConnectConfig.certificacionesApiUrl + this.url,{
                    method: this.method,
                    headers: {
                        'Content-Type': 'application/json',
                        'Auth-Token': ConnectConfig.certificacionesToken,
                    },
                })
                .then(r =>  r.json()
                .then(data => ({status: r.status, body: data})))
                .then(obj => resolve(
                    obj
                ))
                .catch(error => {
                    reject(error);
                })
            }
            else{
                fetch( ConnectConfig.certificacionesApiUrl + this.url,{
                    method: this.method,
                    headers: {
                        'Content-Type': 'application/json',
                        'Auth-Token': ConnectConfig.certificacionesToken,
                    },
                    body: JSON.stringify(this.body)
                })
                .then(r =>  r.json()
                .then(data => ({status: r.status, body: data})))
                .then(obj => resolve(
                    obj
                ))
                .catch(error => {
                    reject(error);
                })
            }
            
        }); 
    }

}
export default Connect;