import React, { Component } from 'react';
import './RightOverlay.css';

class RightOverlay extends Component {

    constructor(props) {
        super(props);
        this.state = {
            overlayContent: '',
            overlayShowHide: '',
            overlayShow: ''
        }
    }

    render() {
        if (this.props.overlayShow === false) {
            return false;
        }
        else {
            return (
                <div className="right-overlay">
                    {<this.props.overlayContent id={this.props.id} overlayShowHide={this.props.overlayShowHide} />}
                </div>
            )
        }
    }
}
export default RightOverlay;