import React, { Component, Fragment } from 'react';
import HeaderForm from '../Global/HeaderForm';
import Loader from '../Global/Loader';

class Reportes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            overlayShowHide: "",
            buttonDisable: false,
            validation: "needs-validation",
        };
        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.handleSelectEndpoint = this.handleSelectEndpoint.bind(this);
        
        this.sendForm = this.sendForm.bind(this);
    }

    handleInput(e) {
        let fileObj = e.target.files[0];
        this.setState({
            archivo: fileObj
        })
    }

    handleSelectEndpoint(e){
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            validation: "needs-validation was-validated",
        });

        if (this.handleValidation() === true) {
            this.sendForm();
        }
    }

    handleValidation() {
        if (!this.state.archivo) {
            return false;
        } else {
            return true;
        }
    }

    sendForm() {
        this.props.handleSubirArchivo(this.state.archivo, this.state.endpoint);
        this.props.overlayShowHide()
    }

    buttonsAction() {
        if (this.state.buttonDisable === false) {
            return (
                <div className="right-footer d-flex justify-content-center align-items-stretch">
                    <button disabled={this.state.buttonDisable} className="btn btn-primary mr-2" onClick={() => this.handleSubmit}>
                        Subir archivo
                    </button>
                    <button disabled={this.state.buttonDisable} className="btn btn-secondary" onClick={() => this.props.overlayShowHide()}>
                        Cancelar
                    </button>
                </div>
            )
        } else {
            return (
                <div className="right-footer d-flex justify-content-center align-items-stretch">
                    <button disabled={this.state.buttonDisable} className="btn btn-primary mr-2" onClick={() => this.handleButtonShow()}>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                         Subiendo...
                    </button>
                </div>
            )
        }
    }

    render() {
        return (
            <Fragment>

                <form onSubmit={this.handleSubmit} className={this.state.validation} noValidate>
                    <div className="right-content bg-white shadow-lg">
                        <div className="right-header">
                            <div className="row d-flex justify-content-between align-items-center">
                                <div>
                                    <h6>Subir reportes</h6>
                                </div>
                                <div>
                                    <button className="btn btn-close" onClick={() => this.props.overlayShowHide()}>
                                        <i className="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="right-body bg-white">
                            <div className="row">
                                <div className="col-lg-12">

                                    <HeaderForm title="Selecciona el archivo" help="Selecciona el arvhivo de Excel respetando las columnas como el archivo modelo." position="bottom"></HeaderForm>

                                    <div className="input-group mb-3">
                                        <select className="form-control" name="endpoint" onChange={this.handleSelectEndpoint}>
                                            <option value="">Selecciona una opción</option>
                                            <option value="productos">Productos</option>
                                            <option value="transacciones">Transacciones</option>
                                            <option value="usuarios">Usuarios</option>
                                        </select>
                                    </div>

                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Subir</span>
                                        </div>
                                        <div className="custom-file">
                                            <input type="file" name="archivo" className="custom-file-input" onChange={this.handleInput} required />
                                            <label className="custom-file-label">Selecciona el archivo</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.buttonsAction()}
                    </div>
                </form>
            </Fragment>
        )
    }
}


export default Reportes;