import React, { Component, Fragment } from 'react';
import '../TextEditor/Tinymce.css';
import store from '../../store';
import { Link } from 'react-router-dom';
import Loading from '../Global/Loader';
import { Redirect } from 'react-router-dom';

class editTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectLanguage: '',
            selectWidth: '',
            selectHeight: '',
            html: '',
            validation: "needs-validation",
            open: store.getState().openTextEditor,
            templateSeleccionada: null,
            name: '',
            buttonAction: false,
            guardado: false,
            cambio: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.sendForm = this.sendForm.bind(this);
        this.ObtenerTemplate = this.ObtenerTemplate.bind(this)
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
            cambio: true
        });
    }

    handleEditorChange = (e) => {
        this.setState({
            html: e.target.value,
            cambio: true
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            validation: "needs-validation was-validated"
        });
        this.sendForm();
    }

    sendForm() {

        this.setState({
            buttonAction: true
        })

        var myHeaders = new Headers();
        myHeaders.append("Auth-Token", localStorage.getItem('token'));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "nombre": this.state.nombre,
            "idioma": this.state.idioma,
            "html": this.state.html,
            "width": this.state.width,
            "height": this.state.height,
            "orientacion": this.state.orientacion
        });


        // console.log(raw)

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(window.$UrlApiDev + "/templates/" + this.props.id, requestOptions)
            .then(response => response.json())
            .then(result => {
                this.setState({
                    buttonAction: false,
                    guardado: true
                })
            })
            .catch(error => console.log('error', error));
    }

    ObtenerTemplate() {
        var myHeaders = new Headers();
        myHeaders.append("Auth-Token", localStorage.getItem('token'));
        myHeaders.append("Content-Type", "application/json");


        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(window.$UrlApiDev + "/templates/" + this.props.id, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                this.setState({
                    "loading": false,
                    "nombre": result.nombre,
                    "idioma": result.idioma,
                    "html": result.html,
                    "width": result.width,
                    "height": result.height,
                    "orientacion": parseInt(result.orientacion)
                })
            })
            .catch(error => console.log('error', error));
    }

    componentDidMount() {
        this.ObtenerTemplate()
    }

    butonsAction() {
        if (this.state.buttonAction) {
            return (
                <button disabled={true} className="btn btn-primary mr-2" onClick={() => this.handleButtonShow()}>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    <span className="sr-only">Loading...</span>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    <span className="sr-only">Loading...</span>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    <span className="sr-only">Loading...</span>
                         Guardando...
                </button>
            )
        }
        return (
            <Fragment>
                <Link to={"/templates"} className="btn btn-secondary mr-3">
                    Cancelar
                </Link>
                {this.state.cambio ?
                    <button onClick={this.handleSubmit} className="btn btn-primary">Guardar</button>
                    :
                    <button disabled={true} onClick={this.handleSubmit} className="btn btn-primary">Guardar</button>
                }
            </Fragment>
        )
    }

    render() {

        let select = {
            certTypes: ['Aprobacion', 'Participacion'],
            languages: ['Ingles', 'Español', 'Portugues', 'Catalan'],
            templates: ['Oversea', 'Barca']
        };

        if (this.state.loading === true) {
            return (
                <Loading></Loading>
            )
        } else {
            if (this.state.guardado) {
                return (<Redirect to={"/templates"}></Redirect>)
            } else {
                return (
                    <Fragment>
                        <form className={this.state.validation} noValidate>
                            <div className="container-fluid template-create d-flex flex-column align-items-center justify-content-between">
                                <div className="editor-header right-header row d-flex justify-content-between" >
                                    <p>Editar template de Certificado</p>
                                    <Link to={"/templates"} className="btn btn-close">
                                        <i className="fa fa-times"></i>
                                    </Link>
                                </div>

                                <div className="editor-body">

                                    <div className="form-group form-editor d-flex flex-xl-row flex-lg-row flex-md-column flex-sm-columnflex-sm-column flex-xs-column bd-highlight">
                                        <div className="mr-3">
                                            <label>Nombre del template:</label>
                                            <input className="form-control" name="nombre" value={this.state.nombre} onChange={this.handleChange} required />
                                        </div>
                                        <div className="mr-3">
                                            <label>Idioma:</label>
                                            <select className="form-control" name="idioma" value={this.state.idioma} onChange={this.handleChange} required>
                                                <option value="">Selecciona el idioma</option>
                                                <option value="es">Español</option>
                                                <option value="en">Inglés</option>
                                                <option value="pt">Portugues</option>
                                                <option value="ca">Catalán</option>
                                                <option value="ar">Árabe</option>
                                                <option value="ru">Ruso</option>
                                            </select>
                                        </div>
                                        <div className="mr-3">
                                            <label>Ancho:</label>
                                            <select className="form-control" name="width" value={this.state.width} onChange={this.handleChange} required>
                                                <option value="">---</option>
                                                <option value="842">842px</option>
                                            </select>
                                        </div>
                                        <div className="mr-3">
                                            <label>Alto:</label>
                                            <select className="form-control" name="height" value={this.state.height} onChange={this.handleChange} required>
                                                <option value="">---</option>
                                                <option value="605">605px</option>
                                            </select>
                                        </div>
                                        <div className="mr-3">
                                            <label>Orientación:</label>
                                            <select className="form-control" name="orientacion" value={parseInt(this.state.orientacion)} onChange={this.handleChange} required>
                                                <option value="">Seleccione orientación</option>
                                                <option value="0">Horizontal</option>
                                                <option value="1">Vertical</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="variables">
                                        <label className="mr-2"><b>Variables: </b></label>
                                        <span className="badge badge-pill badge-secondary mr-2">##USER_FULL_NAME##</span>
                                        <span className="badge badge-pill badge-secondary mr-2">##QR_IMAGE##</span>
                                        <span className="badge badge-pill badge-secondary mr-2">##DATE_MONTH_NAME##</span>
                                        <span className="badge badge-pill badge-secondary mr-2">##DATE_YEAR##</span>
                                        <span className="badge badge-pill badge-secondary mr-2">##COURSE_NAME##</span>
                                        <span className="badge badge-pill badge-secondary mr-2">##COURSE_LENGHT##</span>
                                    </div>

                                    <textarea
                                        className="form-control"
                                        cols="10"
                                        rows="20"
                                        required={true}
                                        onChange={this.handleEditorChange}
                                        value={this.state.html}
                                    />

                                </div>
                                <footer className="editor-footer row d-flex flex-row bd-highlight justify-content-end pt-3 pb-3">
                                    {this.butonsAction()}
                                </footer>
                            </div>
                        </form>
                    </Fragment>
                )
            }

        }
    }
}


export default editTemplate;