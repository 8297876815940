import React, { Component } from 'react';
import IconLarge from '../Global/IconLarge';
import store from '../../store';

class UsuariosForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            overlayShowHide: "",
            estado: "",
            mensaje: "",
            buttonDisable: false,
            validation: "needs-validation",
            data: {
                usuario_email: '',
                usuario_contrasenia: '',
                usuario_nombre: '',
                usuario_apellido: '',
                usuario_tipo: '',
                usuario_estado: ''
            }
        };
        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.sendForm = this.sendForm.bind(this);
    }

    handleInput(e) {
        const { value, name } = e.target;
        this.setState({
            data: {
                ...this.state.data,
                [name]: value
            }
        })
        // console.log(this.state.data)
    }

    handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            validation: "needs-validation was-validated"
        });
        if (this.handleValidation() === true) {
            this.sendForm();
        };
    }

    handleValidation() {
        var emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if (!emailRegex.test(this.state.data.usuario_email)) {
            return false;
        } else {
            if (!this.state.data.usuario_nombre || !this.state.data.usuario_apellido || !this.state.data.usuario_estado || !this.state.data.usuario_email || !this.state.data.usuario_tipo) {
                return false;
            } else {
                return true;
            }
        }
    }

    handleReRender() {
        store.dispatch({
            type: "RE_RENDER",
            rerender: true
        })
    }

    async sendForm() {
        this.setState({
            buttonDisable: true
        });

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(
            this.state.data
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(window.$UrlApiDev + "/usuarios/", requestOptions)
        .then(r =>  r.json()
        .then(data => ({
            status: r.status, 
            body: data
        })))
        .then(obj => {
            if(obj.status !== 200){
                this.setState({
                    estado: obj.status,
                    mensaje: obj.body,
                    buttonDisable: false
                })
            } else{
                this.setState({
                    estado: obj.status,
                    mensaje: obj.body,
                    buttonDisable: false
                })
                this.handleReRender();
            }
            
        })
        .catch(error => console.log('error', error));            
                
                

    }

    buttonsAction() {
        if (this.state.buttonDisable === false) {
            return (
                <div className="right-footer d-flex justify-content-center align-items-stretch">
                    <button disabled={this.state.buttonDisable} className="btn btn-primary mr-2" onClick={() => this.handleSubmit}>
                        Añadir nuevo usuario
                    </button>
                    <button disabled={this.state.buttonDisable} className="btn btn-secondary" onClick={() => this.props.overlayShowHide()}>
                        Cancelar
                    </button>
                </div>
            )
        } else {
            return (
                <div className="right-footer d-flex justify-content-center align-items-stretch">
                    <button disabled={this.state.buttonDisable} className="btn btn-primary mr-2" onClick={() => this.handleButtonShow()}>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                         Creando...
                    </button>
                </div>
            )
        }
    }

    render() {
        if (this.state.estado === 400 || this.state.estado === 404) {
            return (
                <div className="right-content bg-white shadow-lg">
                    <div className="right-header">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div>
                                <h6>Añadir nuevo Usuario</h6>
                            </div>
                            <div>
                                <button className="btn btn-close" onClick={() => this.props.overlayShowHide()}>
                                    <i className="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="right-body bg-white">
                        <div className="row">
                            <div className="col-lg-12 d-flex flex-column justify-content-center align-items-center">
                                <IconLarge icon="fa-times" size="4" color="text-danger" animation="shake" />
                                <p><b>Ocurrió un error al crear el usuario</b></p>
                                <p>Estado: {this.state.estado} <br></br> {this.state.mensaje.error}</p>
                            </div>
                        </div>
                    </div>
                    <div className="right-footer d-flex justify-content-center align-items-stretch">
                        <button disabled={this.state.buttonDisable} className="btn btn-primary" onClick={() => this.props.overlayShowHide()}>
                            Cerrar
                        </button>
                    </div>
                </div>
            )
        }
        else if (this.state.estado === 200) {
            return (
                <div className="right-content bg-white shadow-lg">
                    <div className="right-header">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div>
                                <h6>Añadir nuevo usuario</h6>
                            </div>
                            <div>
                                <button className="btn btn-close" onClick={() => this.props.overlayShowHide()}>
                                    <i className="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="right-body bg-white">
                        <div className="row">
                            <div className="col-lg-12 d-flex flex-column justify-content-center align-items-center">
                                <IconLarge icon="fa-check" size="4" color="text-success" animation="shake" />
                                <p><b>Se creó el usuario con éxito!</b></p>
                                <p>Estado: {this.state.estado} <br></br></p>
                            </div>
                        </div>
                    </div>
                    <div className="right-footer d-flex justify-content-center align-items-stretch">
                        <button disabled={this.state.buttonDisable} className="btn btn-primary" onClick={() => this.props.overlayShowHide()}>
                            Cerrar
                        </button>
                    </div>
                </div>
            )
        }
        else {
            return (
                <form onSubmit={this.handleSubmit} className={this.state.validation} noValidate>
                    <div className="right-content bg-white shadow-lg">
                        <div className="right-header">
                            <div className="row d-flex justify-content-between align-items-center">
                                <div>
                                    <h6>Añadir nuevo usuario</h6>
                                </div>
                                <div>
                                    <button className="btn btn-close" onClick={() => this.props.overlayShowHide()}>
                                        <i className="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="right-body bg-white">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="usuario_nombre">Nombre</label>
                                        <input id="usuario_nombre" type="text" className="form-control" name="usuario_nombre" placeholder="Nombre" onChange={this.handleInput} required></input>
                                        <div className="invalid-feedback">Ingresa el nombre</div>
                                        <div className="valid-feedback">Excelente!</div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="usuario_apellido">Apellido</label>
                                        <input id="usuario_apellido" type="text" className="form-control" name="usuario_apellido" placeholder="Apellido" onChange={this.handleInput} required></input>
                                        <div className="invalid-feedback">Ingresa el apellido</div>
                                        <div className="valid-feedback">Excelente!</div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="usuario_email">Email</label>
                                        <input id="usuario_email" type="email" className="form-control" name="usuario_email" placeholder="Email" onChange={this.handleInput} required></input>
                                        <div className="invalid-feedback">Ingresa el email</div>
                                        <div className="valid-feedback">Excelente!</div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="usuario_contrasenia">Contraseña</label>
                                        <input id="usuario_contrasenia" type="text" className="form-control" name="usuario_contrasenia" placeholder="Contraseña" onChange={this.handleInput} required></input>
                                        <div className="invalid-feedback">Ingresa la Contraseña</div>
                                        <div className="valid-feedback">Excelente!</div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="usuario_tipo">Tipo de usuario</label>
                                        <select id="usuario_tipo" type="text" className="form-control" name="usuario_tipo" onChange={this.handleInput} required>
                                            <option value="">Selecciona un tipo</option>
                                            <option value="1">Administrador</option>
                                            <option value="2">Autor</option>
                                            <option value="3">Contenidista</option>
                                            <option value="4">Profesor</option>
                                        </select>
                                        <div className="invalid-feedback">Selecciona un entorno</div>
                                        <div className="valid-feedback">Excelente!</div>
                                    </div>

                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input id="activo" type="radio" name="usuario_estado" onChange={this.handleInput} value="1" className="custom-control-input" required />
                                        <label className="custom-control-label" htmlFor="activo">Activo</label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input id="inactivo" type="radio" name="usuario_estado" onChange={this.handleInput} value="0" className="custom-control-input" />
                                        <label className="custom-control-label" htmlFor="inactivo">Inactivo</label>

                                    </div>
                                    <div className="invalid-feedback">El estado es requerido</div>
                                    <div className="valid-feedback">Excelente!</div>
                                </div>
                            </div>
                        </div>
                        {this.buttonsAction()}
                    </div>
                </form>
            )
        }
    }

}



export default UsuariosForm;