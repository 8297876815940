import React, { Component, Fragment } from 'react';
import RightOverlayAV from '../RightOverlay/RightOverlayAV';
import Reportes from '../CertificadosAv/Reportes'

class SecondaryNavigationReportes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: '', // Corresponde al título de la barra de navegación
            textButton: '', // Corresponde al texto del botón que abre el RightOverlay
            overlayContent: '', // Corresponde al contenido que lleva adentro del RightOverlay
            overlayShow: false // Es el estado del RightOverlay
        }
        this.overlayShowHide = this.overlayShowHide.bind(this);
    }


    overlayShowHide() {
        this.setState(prevState => ({
            overlayShow: !prevState.overlayShow
        }));
    }

    render() {
        if (!this.props.textButton) {
            return (
                <Fragment>
                    <div className="bg-light pt-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <label className="navigation-breadcrumb">Inicio > {this.props.title}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="navbarstiky sticky-top bg-white mb-4 pb-3 pt-3">
                        <div className="container">
                            <div className="d-flex flex-column flex-xl-row flex-lg-row flex-md-row flex-sm-row justify-content-between">
                                <h5>{this.props.title}</h5>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className="bg-light pt-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <label className="navigation-breadcrumb">Inicio > {this.props.title}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="navbarstiky sticky-top bg-white mb-4 pb-3 pt-3">
                        <div className="container">
                            <div className="d-flex flex-column flex-xl-row flex-lg-row flex-md-row flex-sm-row justify-content-between">
                                <h5>{this.props.title}</h5>
                                <div className="d-flex justify-content-start align-items-center">
                                    {/* <a href="/productos.xlsx" download className="btn btn-secondary mr-2">
                                        Planilla modelo
                                        <i className="fas fa-download ml-3"></i>
                                    </a> */}
                                    <button type="button" className="btn btn-primary" onClick={() => this.overlayShowHide()}>
                                        {this.props.textButton}
                                    </button>
                                </div>

                                <RightOverlayAV overlayContent={
                                    <Reportes handleSubirArchivo={this.props.handleSubirArchivo} overlayShowHide={this.overlayShowHide}></Reportes>
                                } overlayShowHide={this.overlayShowHide} overlayShow={this.state.overlayShow} />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }
}

export default SecondaryNavigationReportes;