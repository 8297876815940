import React, {Component, Fragment} from 'react';
import RightOverlay from '../RightOverlay/RightOverlay';
import SelectClient from './SelectClient';
import Connect from '../../models/Connect';

class SecondayNavigation extends Component {

    constructor(props){
        super(props);
        this.state = {
            title:'', // Corresponde al título de la barra de navegación
            textButton: '', // Corresponde al texto del botón que abre el RightOverlay
            overlayContent: '', // Corresponde al contenido que lleva adentro del RightOverlay
            overlayShow: false // Es el estado del RightOverlay
        }
        this.overlayShowHide = this.overlayShowHide.bind(this);
    }

    overlayShowHide(){
        this.setState(prevState => ({
            overlayShow: !prevState.overlayShow
        }));
    }

    render(){
        if(!this.props.textButton){
            return (
                <React.Fragment>
                    <div className="bg-light pt-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <label className="navigation-breadcrumb">Inicio > Certificados</label>
                                    <h5>{this.props.title}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        } else{
            return(
                <Fragment>
                    <div className="bg-light pt-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <label className="navigation-breadcrumb">Inicio > Certificados</label>
                                    <h5>{this.props.title}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="navbarstiky sticky-top bg-white mb-4 pb-3 pt-3">
                        <div className="container">
                            <div className="d-flex flex-column flex-xl-row flex-lg-row flex-md-row flex-sm-row justify-content-between">
                                <div className="stickyMenu__cliente mb-2 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-0">
                                    <SelectClient></SelectClient>
                                </div>
                                <button type="button" className="btn btn-primary" onClick={() => this.overlayShowHide()}>
                                    {this.props.textButton}
                                </button>
                                <RightOverlay overlayContent={this.props.overlayContent} overlayShowHide={this.overlayShowHide} overlayShow={this.state.overlayShow}/>
                                
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
        
    }
}

export default SecondayNavigation;