import React, { Component, Fragment } from 'react';
import LoaderSecondary from './LoaderSecondary';

class SelectProducto extends Component {

    constructor(props) {
        super(props)
        this.state = {
            clienteLoading: false,
            productoLoading: false,
            productos: [],
            clientes: []
        }
        this.handleObtenerClientes = this.handleObtenerClientes.bind(this);
        this.handleObtenerProductos = this.handleObtenerProductos.bind(this);
        this.handleClienteSeleccionado = this.handleClienteSeleccionado.bind(this);
    }

    handleClienteSeleccionado(e) {
        this.props.change(e);
        this.setState({
            productos: []
        })
        const { value } = e.target;
        this.handleObtenerProductos(value)
    }

    componentWillMount() {
        this.handleObtenerClientes();
    }

    handleObtenerClientes() {
        this.setState({
            clienteLoading: true
        })

        var myHeaders = new Headers();
        myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(window.$UrlOctavio + "/clientes", requestOptions)
            .then(response => response.json())
            .then(result => {

                var clientes = [];

                for (let i = 0; i < result.length; i++) {
                    clientes.push({
                        'id': result[i].id,
                        'dominio': result[i].dominio
                    })
                }

                this.setState({
                    clienteLoading: false,
                    clientes: clientes
                })
                if (this.props.selectedPrducto !== undefined) {
                    this.handleObtenerProductos(this.props.selectedCliente);
                }
            })
            .catch(error => console.log('error', error));
    }

    handleObtenerProductos(value) {
        this.setState({
            productoLoading: true
        })

        var myHeaders = new Headers();
        myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");
        myHeaders.append("cliente", value);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(window.$UrlOctavio + "/productos", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                var productos = [];
                for (let i = 0; i < result.length; i++) {
                    productos.push({
                        'id': result[i].id,
                        'nombre': result[i].nombre
                    })
                }
                if (result.error) {
                    this.setState({
                        productoLoading: false,
                        productos: false
                    })
                } else {
                    this.setState({
                        productoLoading: false,
                        productos: productos
                    })
                }

            })
            .catch(error => {
                console.log('error', error)
            })
    }

    selectProductos() {

        if (this.state.productos !== false) {


            if (this.state.productos.length !== 0) {
                return (
                    <div className="form-group">
                        <label htmlFor="id_producto">Producto</label>
                        <select value={this.props.selectedPrducto} id="id_producto" type="text" className="form-control" name="id_producto" onChange={this.props.change} required>
                            <option value="">Selecciona el producto</option>
                            {this.state.productos.map(item => {
                                return <option key={item.id} value={item.id}>
                                    {item.id + " - " + item.nombre}
                                </option>
                            })}
                        </select>
                        <div className="invalid-feedback">Selecciona un prducto</div>
                        <div className="valid-feedback">Excelente!</div>
                    </div>
                )
            } else {
                if (this.state.productoLoading === true) {
                    return (<LoaderSecondary texto="Cargando listado de productos"></LoaderSecondary>)
                }
            }
        } else{
            return(
                <div>
                    <p>Ocurrió un error al obtener la información de los productos. Ingresa manualmente el Id de producto de octavio</p>
                    <div className="form-group">
                        <label htmlFor="nombre_producto">Producto Id</label>
                        <input id="id_producto" type="text" className="form-control" name="id_producto" placeholder="Id de producto de Octavio" onChange={this.props.change} required></input>
                        <div className="invalid-feedback">Ingresa el Id de producto de Octavio</div>
                        <div className="valid-feedback">Excelente!</div>
                    </div>
                </div>
            )
        }

    }

    render() {
        if (this.state.clientes.length === 0) {
            return (
                <LoaderSecondary texto="Cargando listado de clientes"></LoaderSecondary>
            )
        } else {
            return (
                <Fragment>
                    <div className="form-group">
                        <label htmlFor="id_cliente">Cliente</label>
                        <select value={this.props.selectedCliente} id="id_cliente" type="text" className="form-control" name="id_cliente" onChange={this.handleClienteSeleccionado} required>
                            <option value="">Selecciona el cliente</option>
                            {this.state.clientes.map(item => {
                                return <option key={`cliente${item.id}`} value={item.id}>{`${item.id} - ${item.dominio}`}</option>
                            })}
                        </select>
                        <div className="invalid-feedback">Selecciona un tipo de certificación</div>
                        <div className="valid-feedback">Excelente!</div>
                    </div>

                    {this.selectProductos()}
                </Fragment>
            )
        }

    }
}

export default SelectProducto;
